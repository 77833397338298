import { getAdvisorBoards } from './company_services';
import { AccountType } from './utils/types';
import { convertFromRaw } from 'draft-js';

export async function sendFSEventOnUpgrade({
  accountDetails,
  currentPlan,
  newPlan,
  source,
  type,
  upgradeType,
  success,
  errorMessage,
  error,
}) {
  let errorInText = 'Not able to parse the error';
  if (!success) {
    try {
      errorInText = JSON.stringify(error);
    } catch (e) {
      console.error('Not able to stringify error', e);
      try {
        errorInText = error.toString();
      } catch (e) {
        console.error('Not able to convert error to string', e);
      }
    }
  }

  try {
    const properties = {
      accountType_str: type === AccountType.ADVISOR ? 'b2c' : 'b2b',
      userSegment_str: accountDetails?.userSegment || '',
      upgradeType_str: upgradeType,
      source_str: source,
      url_str: window.location.href,
      currentPlancode_str: currentPlan?.recurlyPlanCode || '',
      currentPlancodeTier_str: currentPlan?.salesforceValues?.tier || '',
      currentPlanCodeBillingCycle_str:
        currentPlan?.salesforceValues?.billingCycle || '',
      upgradePlancode_str: newPlan?.recurlyPlanCode || '',
      upgradePlancodeTier_str: newPlan?.salesforceValues?.tier || '',
      upgradePlanCodeBillingCycle_str:
        newPlan?.salesforceValues?.billingCycle || '',
      status_str: success ? 'success' : 'failure',
      errorMessage_str: success ? '' : errorMessage,
      technicalErrorMessage_str: success ? '' : errorInText,
    };
    // console.log('Sending FullStory event "Upgrade Attempt"', properties);

    FS('trackEvent', {
      name: 'Upgrade Attempt',
      properties,
    });
  } catch (error) {
    console.error('Error sending FullStory event', error);
  }
}

export async function sendFSEventUpgradeViewed({
  accountDetails,
  currentUserInfo,
  currentPlan,
  source,
  type,
  upgradeType,
  upgradeAttempted,
}) {
  try {
    const properties = {
      cognitoID_str: currentUserInfo.username,
      accountType_str: type === AccountType.ADVISOR ? 'b2c' : 'b2b',
      upgradeType_str: upgradeType,
      upgradeAttempted_str: upgradeAttempted ? 'yes' : 'no',
      userSegment_str: accountDetails?.userSegment || '',
      source_str: source,
      url_str: window.location.href,
      currentPlancode_str: currentPlan?.recurlyPlanCode || '',
      currentPlancodeTier_str: currentPlan?.salesforceValues?.tier || '',
      currentPlanCodeBillingCycle_str: currentPlan?.salesforceValues?.billingCycle || '',
    };
    // console.log('Sending FullStory event "Upgrade Viewed"', properties);

    FS('trackEvent', {
      name: 'Upgrade Viewed',
      properties,
    });
  } catch (error) {
    console.error('Error sending FullStory event', error);
  }
}

export async function sendFSEventInviteViewed({
  companyDisplayName,
  isBulkInvite,
  action,
  outcome,
  inviteId,
  error,
  isFreeTrial,
}) {
  try {
    let errorInText = 'Not able to parse the error';
    if (error && outcome === 'error') {
      try {
        errorInText = JSON.stringify(error);
      } catch (e) {
        console.error('Not able to stringify error', e);
        try {
          errorInText = error.toString();
        } catch (e) {
          console.error('Not able to convert error to string', e);
        }
      }
    }

    const properties = {
      url_str: window.location.href,
      bulk_bool: isBulkInvite ?? false,
      action_str: action, // 'accept decline close',
      outcome_str: outcome, //'joined declined error' blank if slide was closed
      error_str: outcome === 'error' ? errorInText : '',
      companyName_str: companyDisplayName,
      inviteId_str: inviteId,
      isFreeTrail_bool: isFreeTrial,
    };
    // console.log('Sending FullStory event "ITJ viewed"', properties);
    FS('trackEvent', {
      name: 'ITJ viewed',
      properties,
    });
  } catch (error) {
    console.error('Error sending FullStory event', error);
  }
}

export async function sendFSEventInvitedAdvisor({
  advisorId,
  outcome,
  inviteId,
  source,
  invitationNote,
  invitationIncludedNote,
  // error,
}) {
  try {
    // let errorInText = 'Not able to parse the error';
    // if (error && outcome === 'error') {
    //   try {
    //     errorInText = JSON.stringify(error);
    //   } catch (e) {
    //     console.error('Not able to stringify error', e);
    //     try {
    //       errorInText = error.toString();
    //     } catch (e) {
    //       console.error('Not able to convert error to string', e);
    //     }
    //   }
    // }
    const { data } = await getAdvisorBoards({
      ID: advisorId,
      REQUEST_BOARDS: true,
    });
    const advisor = data.getAdvisor;
    const boards = advisor.boards
      ? advisor.boards.memberBoards.filter(
          (board) =>
            board.boardType !== 'P2P' &&
            !board.isAcPlus &&
            !board.isComplimentaryBoardPosition
        )
      : [];

    const properties = {
      source_str: source, // card, profile
      url_str: window.location.href,
      outcome_str: outcome, //'invited canceled error'
      inviteeSlug_str: advisor.publicProfileUrl,
      inviteeTitle_str: advisor.title,
      inviteeBoardsHasJoined_int: boards.length,
      inviteeRegistrationDate_date: advisor.createdAt,
      inviteeFreeTrial__bool: advisor.freeTrialEndsOn
        ? advisor.freeTrialEndsOn > new Date().toISOString()
        : false,
      inviteId_str: inviteId,
      note_str: invitationNote,
      itjSentWithNote_bool: invitationIncludedNote
    };
    // console.log('Sending FullStory event "ITJ modal triggered"', properties);

    FS('trackEvent', {
      name: 'ITJ modal triggered',
      properties,
    });
  } catch (error) {
    console.error('Error sending FullStory event ITJ modal', error);
  }
}

export async function sendFSEventPostPopUp({
  type,
  body,
  attachments,
  posted,
  accountDetails,
  video,
}) {
  try {
    let text = '';
    if (body) {
      const parsedContent = JSON.parse(body);
      const contentState = convertFromRaw(parsedContent);
      text = contentState.getPlainText('');
    }

    const properties = {
      URL_str: window.location.href,
      type_str: type,
      postText_str: text,
      characterCount_int: text.length,
      attachments_int: attachments.length,
      video_bool: video,
      posted_bool: posted,
      advisorCount_int: accountDetails.boardAdvisors.length,
      freeTrial_bool: accountDetails.freeTrialEndsOn
        ? accountDetails.freeTrialEndsOn > new Date().toISOString()
        : false,
    };
    // console.log(
    //   'Sending FullStory event "Post Creation triggered"',
    //   properties
    // );

    FS('trackEvent', {
      name: 'Post Creation triggered',
      properties,
    });
  } catch (error) {
    console.error(
      'Error sending FullStory event Post Creation triggered',
      error
    );
  }
}

export async function sendFSAdvisorsSearched({
  accountDetails,
  keywords,
  skills = [],
  industries = [],
  invitations,
}) {
  if (!keywords && !skills.length && !industries.length) {
    return;
  }
  try {
    const properties = {
      URL_str: window.location.href,
      keywords_str: keywords,
      skills_str: skills,
      industries_str: industries,
      invitations_str: invitations,
      freeTrial_bool: accountDetails.freeTrialEndsOn
        ? accountDetails.freeTrialEndsOn > new Date().toISOString()
        : false,
    };
    // console.log(
    //   'Sending FullStory event "Advisor Search Preformed"',
    //   properties
    // );

    FS('trackEvent', {
      name: 'Advisor Search Preformed',
      properties,
    });
  } catch (error) {
    console.error(
      'Error sending FullStory event Advisor Search Preformed',
      error
    );
  }
}

export async function sendFSEventJoinPageViewed({
  advisor,
  company,
  error,
  action,
  outcome,
  isFavorite,
  opportunityPlacement,
}) {
  try {
    const advisorInvitation = advisor.boards.invitedBoards.some(
      (board) => board.id === company.id
    );
    const advisorMember = advisor.boards.memberBoards.some(
      (board) => board.id === company.id
    );
    const numberOfBoards = advisor.boards.memberBoards.filter(
      (board) => board.boardType !== 'P2P'
    ).length;
    const matchedSkillsCount =
      advisor.skillTagIds?.filter((skill) =>
        company.desiredSkillsTagIds?.includes(skill)
      ).length || 0;
    const companyBenefitsLength = company.benefits
      ? company.benefits.length
      : 0;

    const freeTrial = advisor.freeTrialEndsOn ? true : false;
    const futureBoardMeeting = company.upcomingBoardMeeting ? true : false;

    const properties = {
      URL_str: window.location.href,
      companySlug_str: company.slug,
      invitation_bool: advisorInvitation,
      action_str: action,
      outcome_str: outcome,
      errorMessage_str: error || null,
      member_bool: advisorMember,
      companyBenefits_int: companyBenefitsLength,
      futureBoardMeeting_bool: futureBoardMeeting,
      saved_bool: isFavorite,
      freeTrail_bool: freeTrial,
      numberOfBoards_int: numberOfBoards,
      opportunityPlacement_int: opportunityPlacement,
      matchedSkillsCount_int: matchedSkillsCount,
    };

    // console.log(
    //   'Successfully sent FullStory event "Join Page Viewed":',
    //   properties
    // );

    FS('trackEvent', {
      name: 'Join Page Viewed',
      properties,
    });
  } catch (error) {
    console.error('Error sending FullStory event Join Page Viewed', error);
  }
}
