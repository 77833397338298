import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { isNil, getAdvisorImagePath } from './../../../services/utils';
import { useAccount } from './../../../contexts/Account';
import AdvisorItem from './AdvisorItem';
import CardSkeleton from '../../SkeletonLoading/cardSkeleton';
import eventBus, { eventBusValues } from './../../../eventBus';
import { Box, Button, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useMediaQuery } from '@mui/material';

const useStyles = () => ({
  removed: {
    display: 'none',
  },
  infinteScrollContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadMoreButton: {
    color: '#232B35',
    textTransform: 'none',
    size: '16px',
    fontWeight: 500,
    letterSpacing: '-0.43px',
    height: '48px',
  },
  loadMoreText: {
    color: '#646D7A',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    textAlign: 'center',
    cursor: 'pointer',
    padding: '0 10px',
  },
});

function TopMatchesList(props) {
  const {
    advisorList,
    removeBio,
    buttonTitles,
    isLoading,
    requestMeetingClickAction,
    isFavoritePage,
    onClickAction,
    refresh,
    endPagination,
    onSelectAdvisor,
    selectedAdvisors,
    CustomCheckbox,
    loadAdvisors,
  } = props;
  const classes = useStyles();
  const [boardAdvisorsMap, setBoardAdvisorsMap] = useState();
  const [invitedAdvisorsMap, setInvitedAdvisorsMap] = useState();
  const [displayedAdvisorsCount, setDisplayedAdvisorsCount] = useState(0);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const location = useLocation();
  const { accountDetails, tags, isFreemium } = useAccount();

  let advisorListFiltered = [
    ...new Map(advisorList.map((item) => [item.id, item])).values(),
  ];

  if (location.pathname.includes('/advisors') && !isFreemium) {
    if (!location.pathname.includes('/advisors/') && advisorList) {
      advisorListFiltered = advisorListFiltered.filter((advisor) =>
        ['active', 'new'].includes(advisor.accountStatus)
      );
    }
  }

  const loader = useRef();

  useEffect(() => {
    const newInvitedAdvisorsMap = {};
    const invitedAdvisorsNew = accountDetails.invitedAdvisors || [];

    for (let i = 0; i < invitedAdvisorsNew.length; i += 1) {
      newInvitedAdvisorsMap[invitedAdvisorsNew[i].id] = {
        inviteDate: invitedAdvisorsNew[i].inviteDate,
      };
    }

    setInvitedAdvisorsMap(newInvitedAdvisorsMap);
  }, [refresh]);

  useEffect(() => {
    setDisplayedAdvisorsCount(advisorListFiltered.length);
  }, [advisorListFiltered]);

  function mapSkills(skills) {
    if (!isNil(skills) && tags.skills) {
      const filteredArray = tags.skills.filter((element) =>
        skills.includes(String(element.id))
      );
      const mapSkillTag = filteredArray.map((element) => {
        return element.label;
      });
      return mapSkillTag;
    }
    return [];
  }

  function loadMore() {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            maxWidth: '376px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {!endPagination && (
            <Button
              sx={classes.loadMoreButton}
              onClick={loadAdvisors}
              startIcon={<RefreshIcon sx={{ color: '#4BC6FF' }} />}
            >
              Load 10 more
            </Button>
          )}

          <Typography sx={classes.loadMoreText}>
            Not seeing the results you want?
          </Typography>
          <Typography sx={classes.loadMoreText}>
            Try updating your{' '}
            <span
              onClick={handleProfileSettingsClick}
              style={{
                textDecoration: 'underline',
                color: '#3171F6',
                cursor: 'pointer',
              }}
            >
              profile settings
            </span>{' '}
            to ensure your desired skills are in-line with your needs.
          </Typography>
        </Box>
      </Box>
    );
  }

  const handleProfileSettingsClick = () => {
    eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
      company: accountDetails,
    });
  };

  if (!invitedAdvisorsMap) {
    const newInvitedAdvisorsMap = {};
    const { invitedAdvisors } = accountDetails;

    if (!invitedAdvisors) {
      setInvitedAdvisorsMap({});
      return null;
    }

    for (let i = 0; i < invitedAdvisors.length; i += 1) {
      newInvitedAdvisorsMap[invitedAdvisors[i].id] = {
        inviteDate: invitedAdvisors[i].inviteDate,
      };
    }

    setInvitedAdvisorsMap(newInvitedAdvisorsMap);
    return null;
  }

  if (!boardAdvisorsMap) {
    const newBoardAdvisorsMap = {};
    const { boardAdvisors } = accountDetails;

    if (!boardAdvisors) {
      setBoardAdvisorsMap({});
      return null;
    }

    for (let i = 0; i < boardAdvisors.length; i += 1) {
      newBoardAdvisorsMap[boardAdvisors[i].id] = {
        joinedOnDate: boardAdvisors[i].joinedOnDate,
      };
    }

    setBoardAdvisorsMap(newBoardAdvisorsMap);
    return null;
  }

  return (
    <Box
      sx={{
        marginBottom: '72px',
        padding: isSM ? '0' : '0px 30px',
      }}
    >
      {advisorListFiltered.length > 0 &&
        advisorListFiltered.map((advisor, idx) => {
          if (advisor.id === accountDetails.id) return null;
          return (
            <Box
              key={advisor.id}
              style={{
                display: 'flex',
                alignItems: 'baseline',
                width: '100%',
                padding: isSM ? '0 15px' : '0px',
              }}
            >
              {!isSM && (
                <CustomCheckbox
                  checked={selectedAdvisors.includes(advisor.id)}
                  onChange={() => onSelectAdvisor(advisor.id)}
                  className="top-match-checkbox"
                />
              )}
              <AdvisorItem
                advisor={advisor}
                key={advisor.id}
                requestMeetingClick={() => {
                  requestMeetingClickAction(advisor);
                }}
                invitedToJoinDate={
                  invitedAdvisorsMap[advisor.id]
                    ? invitedAdvisorsMap[advisor.id].inviteDate
                    : null
                }
                showBottom={boardAdvisorsMap[advisor.id]}
                advisorName={advisor.displayName}
                advisorInfo={advisor.title}
                advisorAvatar={getAdvisorImagePath(advisor)}
                skills={mapSkills(advisor.skillTagIds)}
                bio={advisor.biography ? advisor.biography : ''}
                removeBio={removeBio || !advisor.biography}
                advisorDetailData={advisor}
                isLoading={isLoading}
                isStar
                cardClick={() => onClickAction(advisor)}
                isCardClickable
                isFavoritePage={isFavoritePage}
                topMatch
                topMatchCheckbox={<CustomCheckbox
                  checked={selectedAdvisors.includes(advisor.id)}
                  onChange={() => onSelectAdvisor(advisor.id)}
                  className="top-match-checkbox"
                />}
                displayTopMatchBadge={idx < 50}
              />
            </Box>
          );
        })}
      {isLoading && (
        <Box
          sx={{ maxWidth: '1200px', marginLeft: '40px', marginRight: '0px' }}
        >
          <CardSkeleton />
          <Box sx={{ height: '30px' }} />
          <CardSkeleton />
        </Box>
      )}

      {advisorListFiltered.length > 0 && loadMore()}
      <div ref={loader} />
    </Box>
  );
}

export default TopMatchesList;
