import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, useMediaQuery } from '@mui/material';
import LoadingIndicatorLogo from '../LoadingIndicatorLogo';
import ACSnackbar from '../ACSnackbar';
import { useAccount } from '../../contexts/Account';
import {
  requestJoinBoard,
  respondBoardInvitation,
  getAdvisorRecurlyPlan,
  getIsFavoriteCompany,
} from '../../services/advisor_services';
import RequestModal from './RequestModal';
import NoMoreBoardSeats from '../Company/NoMoreBoardSeats';
import eventBus, { eventBusValues } from '../../eventBus';
import { AccountType } from '../../services/utils/types';
import useCanMessage from '../../hooks/useMessagePermission';
import { sendFSEventInviteViewed } from '../../services/FullStory';

const useStyles = (isSM) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  mainButton: {
    fontWeight: 700,
    width: isSM ? '90%' : '14vw',
    minWidth: '150px',
    height: isSM ? '55px' : '45px',
    marginBottom: isSM ? '10px' : '20px',
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontSize: '15px',
    borderRadius: '40px',
    backgroundColor: '#6736FA',
    borderColor: '#6736FA',
    color: '#FFF',
    '&:hover': {
      background: '#6736FA',
    },
    '&:disabled': {
      backgroundColor: '#FFF',
      fontFamily: 'Poppins',
      border: '2px solid #6736FA',
      color: '#6736FA !important',
      borderColor: '#6736FA !important',
    },
    whiteSpace: 'nowrap',
  },
  declineMainButton: {
    fontWeight: 700,
    width: isSM ? '90%' : '14vw',
    minWidth: '150px',
    height: isSM ? '55px' : '45px',
    marginBottom: isSM ? '10px' : '20px',
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontSize: '15px',
    background: '#FFF',
    borderColor: '#0F78FD',
    borderRadius: '40px',
    color: '#0F78FD',
    marginLeft: isSM ? '0px' : '15px',
    whiteSpace: 'nowrap',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: isSM ? 'inherit' : 'auto',
  },
  loadingContainer: {
    alignItems: 'center',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    left: '0px',
    position: 'fixed',
    right: '0px',
    top: '0px',
  },
});

function BoardJoinButton(props) {
  const {
    boardId,
    company,
    staticClass,
    companyCEO,
    hideDeclineInvitation,
    invitation,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles(isSM);
  const { accountDetails, type, isFreeTrial, conversations, isFreemium } =
    useAccount();
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [isPending, setIsPending] = useState(
    accountDetails.boards.appliedBoards.some((bc) => bc.id === boardId)
  );
  const [isJoined, setIsJoined] = useState(
    accountDetails.boards.memberBoards.some((bc) => bc.id === boardId)
  );
  const [isInvited, setIsInvited] = useState(
    accountDetails.boards.invitedBoards.some((bc) => bc.id === boardId)
  );
  const canMessage = useCanMessage(companyCEO.id, AccountType.COMPANY);

  useEffect(() => {
    setIsPending(
      accountDetails.boards.appliedBoards.some((bc) => bc.id === boardId)
    );
    setIsJoined(
      accountDetails.boards.memberBoards.some((bc) => bc.id === boardId)
    );
    setIsInvited(
      accountDetails.boards.invitedBoards.some((bc) => bc.id === boardId)
    );
  }, [boardId]);

  const [snackMessage, setSnackMessage] = useState('');
  const [upgradePlanModal, setUpgradePlanModal] = useState(false);

  async function goToNewBoardroom() {
    eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
      close: true,
    });
    history.push({
      pathname: `/${company.slug}/boardroom`,
    });
  }

  async function secondStepJoin() {
    const now = new Date().toISOString();
    try {
      const response = await requestJoinBoard({
        ADVISOR_ID: accountDetails.id,
        COMPANY_ID: boardId,
        IS_FREE_TRIAL: isFreeTrial,
      });

      const companyHasInstantJoin = response.data.requestJoinBoard;

      if (companyHasInstantJoin) {
        setSnackMessage(
          t('YOUR-APPLICATION-HAS-BEEN-ACCEPTED', {
            companyName: company.displayName,
          })
        );
        setSuccessSnackbar(true);
        setIsDisabled(false);
        accountDetails.boards.memberBoards.push({
          createdAt: now,
          id: boardId,
          companyLogo: company.image ? `${company.image.location}` : '',
          companyName: company.displayName,
          slug: company.slug,
          isComplimentaryBoardPosition: company.complimentaryBoardPosition,
          boardType: company.boardType,
        });
        setIsJoined(true);
        goToNewBoardroom();
      } else {
        setOpenRequestModal(true);
        setIsDisabled(false);
        accountDetails.boards.appliedBoards.push({
          createdAt: now,
          id: boardId,
          companyLogo: company.image ? `${company.image.location}` : '',
          companyName: company.displayName,
          slug: company.slug,
          isComplimentaryBoardPosition: company.complimentaryBoardPosition,
          boardType: company.boardType,
        });
        eventBus.dispatch(eventBusValues.advisorRequestedToJoinCompany);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function join() {
    if (isFreemium) {
      eventBus.dispatch(eventBusValues.triggerAdvisorUpgradeAction);
    } else {
      setIsDisabled(true);
      getAdvisorRecurlyPlan({
        recurlyPlanCode: accountDetails.recurlyPlanCode,
      })
        .then((result) => {
          if (result.data && result.data.getRecurlyPlan) {
            const boardsAvailableValidation = !invitation.bulk
              ? true
              : accountDetails.boards.memberBoards.filter(
                  (x) =>
                    !x.isComplimentaryBoardPosition && x.boardType !== 'P2P'
                ).length +
                  accountDetails.boards.appliedBoards.filter(
                    (x) =>
                      !x.isComplimentaryBoardPosition && x.boardType !== 'P2P'
                  ).length <
                result.data.getRecurlyPlan.boardsCanJoinCount;

            if (company.boardType === 'P2P') {
              const p2pBoardCount =
                accountDetails.boards.memberBoards.filter(
                  (x) => x.boardType === 'P2P'
                ).length +
                accountDetails.boards.appliedBoards.filter(
                  (x) => x.boardType === 'P2P'
                ).length;
              if (p2pBoardCount < 1) {
                secondStepJoin();
              } else {
                setIsDisabled(false);
                setSnackMessage(
                  'You have already joined a Peer Advisory Board, please resign from that board in order to join another.'
                );
                setErrorSnackbar(true);
              }
            } else if (
              company.complimentaryBoardPosition ||
              result.data.getRecurlyPlan.boardsCanJoinCount === -1 ||
              boardsAvailableValidation
            ) {
              secondStepJoin();
            } else {
              setIsDisabled(false);
              setUpgradePlanModal(true);
            }
          } else {
            console.error('err');
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  function acceptInvitation() {
    invitation.status = 'accepted';
    if (isFreemium) {
      eventBus.dispatch(eventBusValues.triggerAdvisorUpgradeAction);
    } else {
      setIsDisabled(true);
      getAdvisorRecurlyPlan({
        recurlyPlanCode: accountDetails.recurlyPlanCode,
      }).then((result) => {
        if (result.data && result.data.getRecurlyPlan) {
          const boardsAvailableValidation = !invitation.bulk
            ? true
            : accountDetails.boards.memberBoards.filter(
                (x) => !x.isComplimentaryBoardPosition && x.boardType !== 'P2P'
              ).length +
                accountDetails.boards.appliedBoards.filter(
                  (x) =>
                    !x.isComplimentaryBoardPosition && x.boardType !== 'P2P'
                ).length <
              result.data.getRecurlyPlan.boardsCanJoinCount;

          const p2pBoardCount =
            accountDetails.boards.memberBoards.filter(
              (x) => x.boardType === 'P2P'
            ).length +
            accountDetails.boards.appliedBoards.filter(
              (x) => x.boardType === 'P2P'
            ).length;

          if (
            company.complimentaryBoardPosition ||
            (company.boardType === 'P2P' && p2pBoardCount < 1) ||
            result.data.getRecurlyPlan.boardsCanJoinCount === -1 ||
            boardsAvailableValidation
          ) {
            respondBoardInvitation({
              ADVISOR_ID: accountDetails.id,
              COMPANY_ID: boardId,
              STATUS: 'accepted',
              IS_FREE_TRIAL: isFreeTrial,
            })
              .then(async () => {
                await sendFSEventInviteViewed({
                  companyDisplayName: company.displayName,
                  isBulkInvite: invitation.bulk,
                  action: 'accept',
                  outcome: 'joined',
                  inviteId: invitation.invitationId,
                  error: null,
                  isFreeTrial: isFreeTrial ? true : false,
                });
                setSnackMessage('BOARD-INVITATION-HAS-BEEN-ACCEPTED');
                setSuccessSnackbar(true);
                const now = new Date();
                setIsDisabled(false);
                accountDetails.boards.memberBoards.push({
                  createdAt: now.toISOString(),
                  id: boardId,
                  companyLogo: company.image ? `${company.image.location}` : '',
                  companyName: company.displayName,
                  slug: company.slug,
                });

                const index = accountDetails.boards.invitedBoards.findIndex(
                  (bc) => bc.id === boardId
                );
                if (index > -1) {
                  accountDetails.boards.invitedBoards.splice(index, 1);
                }
                eventBus.dispatch(
                  eventBusValues.advisorAnsweredInvitation,
                  true
                );
                goToNewBoardroom();
              })
              .catch(async (err) => {
                await sendFSEventInviteViewed({
                  companyDisplayName: company.displayName,
                  isBulkInvite: invitation.bulk,
                  action: 'accept',
                  outcome: 'error',
                  inviteId: invitation.invitationId,
                  error: err,
                  isFreeTrial: isFreeTrial ? true : false,
                });
                console.log(err);
              });
          } else {
            setIsDisabled(false);
            setUpgradePlanModal(true);
          }
        } else {
          console.error('err');
        }
      });
    }
  }

  function declineInvitation() {
    setIsDisabled(true);
    invitation.status = 'declined';
    respondBoardInvitation({
      ADVISOR_ID: accountDetails.id,
      COMPANY_ID: boardId,
      STATUS: 'declined',
      IS_FREE_TRIAL: isFreeTrial,
    })
      .then(async () => {
        await sendFSEventInviteViewed({
          action: 'decline',
          outcome: 'declined',
          inviteId: invitation.invitationId,
          companyDisplayName: company.displayName,
          isBulkInvite: invitation.bulk,
          error: null,
          isFreeTrial: isFreeTrial ? true : false,
        });
        setSnackMessage('BOARD-INVITATION-HAS-BEEN-DECLINED');
        setSuccessSnackbar(true);
        setIsDisabled(false);
        const index = accountDetails.boards.invitedBoards.findIndex(
          (bc) => bc.id === boardId
        );
        if (index > -1) {
          accountDetails.boards.invitedBoards.splice(index, 1);
        }
        eventBus.dispatch(eventBusValues.advisorAnsweredInvitation, false);
      })
      .catch(async (err) => {
        await sendFSEventInviteViewed({
          action: 'decline',
          outcome: 'error',
          inviteId: invitation.invitationId,
          companyDisplayName: company.displayName,
          isBulkInvite: invitation.bulk,
          error: err,
          isFreeTrial: isFreeTrial ? true : false,
        });
        ('declined');
        console.log(err);
      });
  }

  function buttonLabel() {
    return isPending
      ? t('BOARD-APPLICATION-PENDING')
      : company.instantJoinEnabled
      ? t('JOIN-THIS-BOARD')
      : t('REQUEST-TO-JOIN');
  }

  function conversationClick() {
    if (canMessage === undefined) return;
    if (canMessage) {
      const found = conversations.find(
        (conversation) =>
          conversation.conversationMemberAccountId === companyCEO.id
      );
      if (found) {
        window.location.replace(
          `${window.location.origin}/messages?conversationId=${found.conversationId}`
        );
      } else {
        eventBus.dispatch(eventBusValues.onSendConversationNewMessage, {
          senderId: companyCEO.id,
          senderName: companyCEO.displayName,
        });
      }
    } else {
      eventBus.dispatch(
        type === AccountType.COMPANY
          ? eventBusValues.triggerCompanyUpgradeAction
          : eventBusValues.triggerAdvisorUpgradeAction,
        {}
      );
    }
  }

  function renderButton() {
    return isInvited ? (
      <div style={classes.buttonsContainer}>
        <Button
          variant="outlined"
          onClick={() => acceptInvitation()}
          sx={classes.mainButton}
          disabled={isDisabled}
        >
          {t('BOARD-ACCEPT-INVITATION')}
        </Button>
        {hideDeclineInvitation ? null : (
          <Button
            variant="outlined"
            onClick={() => declineInvitation()}
            sx={classes.declineMainButton}
            disabled={isDisabled}
          >
            {t('BOARD-DECLINE-INVITATION')}
          </Button>
        )}
      </div>
    ) : (
      <Button
        variant="outlined"
        onClick={isJoined ? () => conversationClick() : () => join()}
        className={staticClass || ''}
        sx={classes.mainButton}
        style={{
          display:
            location.pathname.includes('/messages') && isJoined
              ? 'none'
              : 'flex',
        }}
        disabled={isPending || isDisabled}
      >
        {isJoined ? t('CHAT') : buttonLabel()}
      </Button>
    );
  }

  useEffect(() => {
    eventBus.on(eventBusValues.advisorRequestedToJoinCompany, () => {
      setIsPending(true);
    });

    eventBus.on(eventBusValues.advisorAnsweredInvitation, (isAccepted) => {
      setIsInvited(false);
      if (isAccepted) {
        setIsJoined(true);
      }
    });

    return () => {
      eventBus.remove(eventBusValues.advisorRequestedToJoinCompany);
      eventBus.remove(eventBusValues.advisorAnsweredInvitation);
    };
  }, []);

  return (
    <>
      <NoMoreBoardSeats
        openModal={upgradePlanModal}
        setOpenModal={setUpgradePlanModal}
      />
      <ACSnackbar
        open={successSnackbar}
        text={snackMessage ? t(snackMessage) : ''}
        severity="success"
        onClose={() => setSuccessSnackbar(false)}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <ACSnackbar
        open={errorSnackbar}
        text={snackMessage ? t(snackMessage) : ''}
        severity="error"
        onClose={() => setErrorSnackbar(false)}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <RequestModal isOpen={openRequestModal} setIsOpen={setOpenRequestModal} />
      {isDisabled ? (
        <div style={classes.loadingContainer}>
          <LoadingIndicatorLogo size={200} iconFontSize={93} iconRight={105} />
        </div>
      ) : null}
      <div style={classes.container}>{renderButton(true)}</div>
    </>
  );
}

export default BoardJoinButton;
