import React, { useEffect, useState } from 'react';
import {
  useMediaQuery,
  Dialog,
  Typography,
  Button,
  Avatar,
  Box,
  Slide,
  CircularProgress,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Clear } from '@mui/icons-material';
import ACSnackbar from './../../ACSnackbar';
import eventBus, { eventBusValues } from './../../../eventBus';
import { SnackbarType } from './../../../services/utils/types';
import CustomIcon from './../../../icons/customIcon';
import StarSymbol from './../Profile/components/star-symbol.svg';
import { getAdvisorImagePath } from './../../../services/utils';
import { useAccount } from './../../../contexts/Account';
import { upgradeFeatureAdvisor } from './../../../services/advisor_services';
import {
  sendFSEventUpgradeViewed,
  sendFSEventOnUpgrade,
} from '../../../services/FullStory';

const useStyles = ({ isSM }) => ({
  dialogContainer: {
    borderRadius: isSM ? '0px' : '15px',
    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.07)',
    padding: isSM ? '5px' : '30px',
    overflowX: 'hidden',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: isSM ? '10px' : '50px',
    paddingRight: isSM ? '10px' : '50px',
  },
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-center',
    marginTop: isSM ? '5px' : '10px',
    padding: isSM ? '20px' : '1% 15% 4% 15%',
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAllign: 'center',
  },
  titleText: {
    color: 'var(--Black, #232B35)',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: 20,
    fontWeight: 800,
    lineHeight: 'normal',
    letterSpacing: '-0.43px',
  },
  benefitsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '34px',
  },
  benefitBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    borderRadius: 20,
    background: 'rgba(245, 247, 250, 0.59)',
    padding: '17px 26px 17px 26px',
  },
  benefitTitle: {
    color: 'var(--Black, #232B35)',
    fontFamily: 'Poppins',
    fontSize: '19px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '24px', // 133.333%
    letterSpacing: '-0.43px',
    textAlign: isSM ? 'center' : 'left',
  },
  benefitSubtitle: {
    marginTop: '5px',
    color: 'var(--Gray-2, #646D7A)',
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '22px', // 146.667%
    letterSpacing: '-0.5px',
  },
  benefitText: {
    display: 'flex',
    flexDirection: 'column',
  },
  benefitIcon: {
    marginRight: '35px',
    marginleft: '10px',
  },
  upgradeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    height: '100px',
  },

  mainUpgradeButton: {
    width: isSM ? '90%' : '50%',
    height: '65px',
    borderRadius: '40px',
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: 17,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    textTransform: 'none',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '24px',
  },
  confirmContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  confirmButton: {
    width: isSM ? '100%' : '60%',
    height: '65px',
    borderRadius: '40px',
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: 17,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    textTransform: 'none',
  },
  confirmPriceText: {
    color: 'var(--Black, #232B35)',
    fontFeatureSettings: 'clig off, liga off',
    fontFamily: 'Poppins',
    fontSize: '36px',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: 'normal',
    letterSpacing: '-1.5px',
    textAlign: isSM ? 'center' : 'left',
  },
});

const errorCodes = {
  recurlyError: 'RECURLY_ERROR',
  dynamoError: 'DYNAMO_DB_ERROR',
  salesforceError: 'SALESFORCE_ERROR',
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AdvisorFeaturedUpgradeModal = () => {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles({ isSM });
  const {
    accountDetails,
    editAccountInformation,
    isFreemium,
    currentPlan,
    currentUserInfo,
    type,
  } = useAccount();
  const [openModal, setOpenModal] = useState(false);
  const [confirmUpgrade, setConfirmUpgrade] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [upgradeTry, setUpgradeTry] = useState(false);
  const [openedFromBanner, setOpenedFromBanner] = useState(false);

  const [snackMessage, setSnackMessage] = useState('');
  const [snackbarSuccess, setSnackbarSuccess] = useState(false);
  const [snackbarError, setSnackbarError] = useState(false);
  const { search } = useLocation();

  useEffect(() => {
    eventBus.on(eventBusValues.triggerUpgradeFeaturedAdvisorAction, (data) => {
      if (data) {
        setOpenedFromBanner(data.fromBanner || false);
      }
      setOpenModal(true);
    });

    const queryParams = new URLSearchParams(search).get('featuredadvisorpopup');
    if (queryParams) {
      setOpenModal(true);
    }

    return () => {
      eventBus.remove(eventBusValues.triggerUpgradeFeaturedAdvisorAction);
    };
  }, []);

  const sendFSEvent = (success, errorMessage = '', error = null) => {
    sendFSEventOnUpgrade({
      accountDetails,
      currentPlan: currentPlan,
      newPlan: {},
      source: openedFromBanner ? 'banner' : 'gate',
      type,
      upgradeType: 'featuredAdvisor',
      success,
      errorMessage: success ? null : errorMessage,
      error: success ? null : error,
    });
  };

  const sendFSViewedEvent = (upgradeAttempted = false) => {
    sendFSEventUpgradeViewed({
      accountDetails,
      currentPlan: currentPlan,
      currentUserInfo,
      source: openedFromBanner ? 'banner' : 'gate',
      type,
      upgradeType: 'featured_advisor',
      upgradeAttempted,
    });
  };

  function handleClose() {
    setOpenModal(false);
    setConfirmUpgrade(false);
    if (!upgradeTry) {
      sendFSViewedEvent(false);
    }
  }

  function triggerConfirm() {
    setConfirmUpgrade(true);
  }

  async function upgradeCall() {
    setIsLoading(true);
    setUpgradeTry(true);
    sendFSViewedEvent(true);
    try {
      const response = await upgradeFeatureAdvisor({ id: accountDetails.id });
      // console.log(response);
      if (response) {
        editAccountInformation({
          featuredAdvisor: true,
        });
        setOpenModal(false);
        setSnackMessage('You are now a Featured Advisor!');
        setSnackbarSuccess(true);
        sendFSEvent(true);
      }
    } catch (error) {
      console.log(JSON.parse(error.errors[0].message));
      let errorMessage =
        'There was an error processing your upgrade, please reach out to customer service to finalize your upgrade.';
      if (
        error.errors &&
        error.errors[0].message &&
        JSON.parse(error.errors[0].message).code === errorCodes.recurlyError &&
        JSON.parse(error.errors[0].message).error &&
        JSON.parse(error.errors[0].message).error.message
      ) {
        errorMessage = `Error: ${
          JSON.parse(error.errors[0].message).error.message
        }`;
      }
      setSnackMessage(errorMessage);
      setSnackbarError(true);
      sendFSEvent(false, errorMessage, error);
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <div>
      <Dialog
        open={openModal}
        fullScreen={isSM}
        onClose={handleClose}
        PaperProps={{
          id: 'freeTrialUpgradeModal',
          sx: classes.dialogContainer,
        }}
      >
        {isSM ? (
          <div style={classes.clearIcon}>
            <Clear onClick={handleClose} style={{ float: 'right' }} />
          </div>
        ) : null}
        <div style={classes.container}>
          <div style={classes.mainContainer}>
            <div style={classes.avatarContainer}>
              <div style={{ display: 'flex', position: 'relative' }}>
                <img
                  src={StarSymbol}
                  alt="star-symbol"
                  style={{
                    position: 'absolute',
                    left: '0px',
                    top: '15px',
                    zIndex: 3,
                    width: '31px',
                    height: '31px',
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    width: '16px',
                    height: '16px',
                    backgroundColor: '#ffffff',
                    left: '5px',
                    top: '20px',
                    zIndex: 2,
                    clipPath:
                      'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
                  }}
                />
                <Box
                  sx={{
                    display: 'inline-block',
                    width: '138px',
                    height: '138px',
                    borderRadius: '50%',
                    background:
                      'linear-gradient(180deg, #4DF4FF 0%, rgba(38, 83, 242, 0.69) 43.75%, rgba(61, 19, 182, 0.63) 80.21%, #6736FA 100%)',
                    padding: '4px',
                  }}
                >
                  <Avatar
                    src={getAdvisorImagePath(accountDetails)}
                    sx={{
                      width: '130px',
                      height: '130px',
                      zIndex: 1,
                    }}
                  />
                </Box>
              </div>
            </div>
            <div style={classes.topContainer}>
              <Typography sx={classes.titleText}>
                Increase your visibility as a Featured Advisor
              </Typography>
              <Typography
                sx={{
                  color: 'var(--Black, #232B35)',
                  textAlign: 'center',
                  fontFamily: 'Poppins',
                  fontSize: 16,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px',
                  letterSpacing: '-0.43px',
                }}
              >
                Get exclusive marketing opportunities and a priority profile
                listing
              </Typography>
            </div>
            <div style={classes.benefitsContainer}>
              <div style={classes.benefitBox}>
                {!isSM && (
                  <div style={{ marginRight: '30px' }}>
                    <CustomIcon
                      iconname="badge"
                      sx={{
                        fontSize: '40px',
                        color: 'rgba(103, 54, 250, 1)',
                      }}
                    />
                  </div>
                )}
                <div style={classes.benefitText}>
                  <Typography sx={classes.benefitTitle}>
                    Featured advisor badge and search priority
                  </Typography>
                  <Typography sx={classes.benefitSubtitle}>
                    Add a badge of trust to your profile while getting priority
                    listing in search results on the platform
                  </Typography>
                </div>
              </div>
              <div style={classes.benefitBox}>
                {!isSM && (
                  <div style={classes.benefitIcon}>
                    <CustomIcon
                      iconname="newspaper"
                      sx={{
                        fontSize: '40px',
                        color: 'rgba(103, 54, 250, 1)',
                      }}
                    />
                  </div>
                )}
                <div style={classes.benefitText}>
                  <Typography sx={classes.benefitTitle}>
                    Featured interview published on AdvisoryCloud.com
                  </Typography>
                  <Typography sx={classes.benefitSubtitle}>
                    Have our team write an article highlighting your experience
                    and goals as an advisor. Great to highlight your expertise
                    and increase your SEO
                  </Typography>
                </div>
              </div>
              <div style={classes.benefitBox}>
                {!isSM && (
                  <div style={classes.benefitIcon}>
                    <CustomIcon
                      iconname="podcast"
                      sx={{
                        fontSize: '40px',
                        color: 'rgba(103, 54, 250, 1)',
                      }}
                    />
                  </div>
                )}

                <div style={classes.benefitText}>
                  <Typography sx={classes.benefitTitle}>
                    Featured guest on The AdvisoryCloud Podcast
                  </Typography>
                  <Typography sx={classes.benefitSubtitle}>
                    Be a featured guest on The AdvisoryCloud Podcast where
                    you'll discuss your background and highlight the topics
                    you're able to advise on
                  </Typography>
                </div>
              </div>
              <div style={classes.upgradeContainer}>
                {confirmUpgrade ? (
                  <Transition
                    in={confirmUpgrade}
                    mountOnEnter
                    unmountOnExit
                    timeout={1000}
                  >
                    <div style={classes.confirmContainer}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: isSM ? 'column-reverse' : 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: isSM
                            ? '80px 20px 0 20px'
                            : '55px 20px 0 20px',
                          marginTop: isSM ? '20px' : 0,
                        }}
                      >
                        <Button
                          size="large"
                          variant="contained"
                          style={{
                            ...classes.confirmButton,
                            marginTop: isSM ? '20px' : 0,
                            backgroundColor: '#6736FA',
                          }}
                          disabled={isLoading}
                          onClick={upgradeCall}
                        >
                          {isLoading ? (
                            <CircularProgress
                              color="secondary"
                              style={{
                                marginRight: '55px',
                                marginLeft: '55px',
                              }}
                            />
                          ) : (
                            'Purchase upgrade'
                          )}
                        </Button>
                        <div
                          style={{
                            marginLeft: isSM ? 0 : '25px',
                          }}
                        >
                          <Typography sx={classes.confirmPriceText}>
                            $595
                          </Typography>
                          <Typography
                            sx={{
                              color: 'var(--Black, #232B35)',
                              fontFamily: 'Poppins',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              lineHeight: 'normal',
                              letterSpacing: '-0.5px',
                              marginTop: '-10px',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            One-Time charge
                          </Typography>
                        </div>
                      </div>
                      <Typography
                        sx={{
                          color: 'var(--Black, #232B35)',
                          textAlign: 'center',
                          fontFamily: 'Poppins',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: 'normal',
                          letterSpacing: '0.5px',
                          marginTop: '22px',
                        }}
                      >
                        Upon upgrading, you can schedule a call with our team to
                        organize your publication and podcast appearance and
                        your credit card on file will be charged.
                      </Typography>
                    </div>
                  </Transition>
                ) : (
                  <Button
                    size="large"
                    variant="contained"
                    style={{
                      ...classes.mainUpgradeButton,
                      backgroundColor: '#6736FA',
                    }}
                    onClick={triggerConfirm}
                  >
                    Become a Featured Advisor
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <ACSnackbar
        open={snackbarSuccess}
        text={snackMessage}
        severity={SnackbarType.SUCCESS}
        onClose={() => {
          setSnackbarSuccess(false);
        }}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <ACSnackbar
        open={snackbarError}
        text={snackMessage}
        severity={SnackbarType.ERROR}
        onClose={() => {
          setSnackbarError(false);
        }}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
    </div>
  );
};

export default AdvisorFeaturedUpgradeModal;
