import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useMediaQuery,
  CircularProgress,
  Typography,
  Paper,
  TextField,
} from '@mui/material';
import ACSnackbar from './../../ACSnackbar';
import ACAvatar from './../../ACAvatar';
import ACButton from './../../ACButton';
import { getAdvisorImagePath } from './../../../services/utils';
import { SnackbarType } from './../../../services/utils/types';
import Treatment from '../../Treatment';
import { featureFlags } from '../../../splitSetup';
import x_icon from "../../../icons/x_icon.svg";
import { useAccount } from '../../../contexts/Account';

const useStyles = (isSM) => ({
  mask: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
    border: 'none',
  },
  container: {
    padding: '25px',
    display: 'flex',
    width: 'calc(100% - 10px)',
    maxWidth: '650px',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '50px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  modalContainer: {
    textAlign: 'center',
    paddingTop: '0px',
    marginBottom: '10px',
    padding: isSM ? '15px' : '25px',
    borderRadius: '10px',
    backgroundColor: "#F5F7FA"
  },
  fillWidth: {
    width: '100%',
  },
  title: {
    color: 'info.main',
    textAlign: 'left',
  },
  alignRight: {
    marginLeft: 'auto',
    display: 'flex',
  },
  alignLeft: {
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  close: {
    color: 'info.light',
    fontWeight: 'normal',
    cursor: 'pointer',
  },
  cancelButtonContainer: {
    marginRight: '8px',
    marginTop: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30px',
  },
  inviteButtonContainer: {
    marginRight: '8px',
    marginTop: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30px',
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  advisorAvatarContainer: {
    marginRight: '20px',
  },
  advisorName: {
    color: 'info.main',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  advisorInfo: {
    color: 'info.light',
    textAlign: 'left',
  },
  flexContainerVertical: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  advisorDetailsContainer: {
    marginBottom: '10px',
  },
  modalLoadingContainer: {
    width: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
  },
});
//TODO: Make Top Matches return old component
function InviteToJoinConfirmationModal(props) {
  const {
    isVisible,
    advisor,
    onConfirm,
    onClose,
    isLoading,
    snackMessage,
    snackbarSuccess,
    setSnackbarSuccess,
    snackbarWarning,
    setSnackbarWarning,
    setInvitationNote,
  } = props;
  const { accountDetails } = useAccount();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();
  const invitation = useRef({})

  if (!isVisible) {
    return null;
  }

  function oldInvitation() {
    return (
      <Paper sx={classes.modalContainer}>
        <Typography
          sx={classes.title}
          variant={isSM ? 'subtitle2_old_font' : 'subtitle1_old_font'}
        >
          {t('INVITE_TO_JOIN_CONFIRMATION', {
            advisorName: advisor.givenName,
          })}
        </Typography>
        <div style={classes.flexContainer}>
          <div
            style={{
              ...classes.alignLeft,
              ...classes.advisorDetailsContainer,
            }}
          >
            <div style={classes.advisorAvatarContainer}>
              <ACAvatar
                style={classes.advisorAvatar}
                image={getAdvisorImagePath(advisor)}
                size={isSM ? 'md' : 'lg-mobile'}
              />
            </div>
            <div style={classes.flexContainerVertical}>
              <Typography variant="caption_old_font" sx={classes.advisorName}>
                {advisor.displayName}
              </Typography>
              {advisor.title && (
                <Typography
                  variant="caption_old_font"
                  sx={classes.advisorInfo}
                >
                  {advisor.title.length > 18
                    ? advisor.title.substring(0, 18) + '...'
                    : advisor.title}
                </Typography>
              )}
            </div>
          </div>
          <div style={classes.alignRight}>
            {isLoading ? (
              <div style={classes.modalLoadingContainer}>
                <CircularProgress />
              </div>
            ) : (
              <>
                <div style={classes.cancelButtonContainer}>
                  <ACButton
                    // color="secondary"
                    variant="outlined"
                    width={isSM ? '64px' : '80px'}
                    style={{
                      height: '30px',
                      paddingTop: 15,
                      paddingBottom: 15,
                      textTransform: 'none',
                      fontWeight: 700,
                      fontSize: 15,
                      height: 40,
                      borderRadius: 30,
                      backgroundColor: '#FFF',
                      border: '2px solid #0F78FD',
                      color: '#0F78FD',
                      borderColor: '#0F78FD !important',
                    }}
                    onClick={() => onClose()}
                  >
                    <Typography
                      variant={isSM ? 'caption_old_font' : 'body2_old_font'}
                    >
                      {t('CANCEL')}
                    </Typography>
                  </ACButton>
                </div>
                <div style={classes.inviteButtonContainer}>
                  <ACButton
                    color="primary"
                    width={isSM ? '64px' : '80px'}
                    style={{
                      height: '30px',
                      backgroundColor: '#0F78FD',
                      paddingTop: 15,
                      paddingBottom: 15,
                      textTransform: 'none',
                      fontFamily: 'Poppins',
                      fontWeight: 700,
                      fontSize: 15,
                      height: 40,
                      borderRadius: 30,
                    }}
                    onClick={() => onConfirm()}
                  >
                    <Typography
                      variant={isSM ? 'caption_old_font' : 'body2_old_font'}
                    >
                      {t('INVITE')}
                    </Typography>
                  </ACButton>
                </div>
              </>
            )}
          </div>
        </div>
      </Paper>
    )
  }

  function newInvitation() {
    return (
      <Paper id='itjPopUp' sx={classes.modalContainer}>
        <div
          style={{
            ...classes.flexContainerVertical,
            ...classes.alignLeft,
            ...classes.advisorDetailsContainer,
            justifyItems: "center",
            alignContent: "center",
            alignItems: "center"
          }}
        >
          <img
            id={"itjClose"}
            alt='close'
            style={{
              marginLeft: "auto",
              cursor: "pointer",
            }}
            onClick={onClose}
            src={x_icon}
          />
          <div>
            <ACAvatar
              style={classes.advisorAvatar}
              image={getAdvisorImagePath(advisor)}
              size={isSM ? 'md' : 'lg-mobile'}
            />
          </div>
          <div>
            <Typography variant="caption_old_font" sx={classes.advisorName}>
              {advisor.displayName}
            </Typography>
          </div>
        </div>
        <div style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "10px",
          alignItems: "center",
          marginTop: "16px",
          marginBottom: "16px"
        }}>
          <Typography
            style={{
              letterSpacing: "-0.43px",
              lineHeight: "24px",
              fontWeight: "500"
            }}
            sx={classes.title}
            variant={isSM ? 'subtitle2_old_font' : 'subtitle1_old_font'}
          >
            {
              `Invite ${advisor.givenName} to your advisory board`
            }
          </Typography>
          <Typography
            sx={classes.title}
            variant={isSM ? 'body' : 'body'}
          >
            {"Include a brief note with your invitation"}
          </Typography>
        </div>
        <TextField
          id='itjNoteText'
          onChange={(e) => {
            const { value } = e.target;
            e.target.value = value.slice(0, 1000)
            if (value.length <= 1000) {
              invitation.current.value = value
              setInvitationNote(value);
            }
          }}
          style={{
            width: isSM ? "20rem" : "35rem",
            backgroundColor: "white",
            borderRadius: "10px",
            borderColor: "#EFF0F6",
            marginBottom: "20px",
            whiteSpace: "pre-wrap",
            borderWidth: "0px !important"
          }}
          sx={{
            "& fieldset": {
              borderWidth: "0 !important"
            }
          }}
          InputProps={{
            style: {
              borderRadius: "10px",
              whiteSpace: "pre-wrap",
              borderWidth: "0px !important",
            },
          }}
          SelectProps={{
            style: {
              borderRadius: "10px",
              borderWidth: "0px !important",
            },
          }}
          multiline={true}
          rows={isSM ? 4 : 6}
          placeholder={`e.g. Hello ${advisor.givenName}, your profile stood out to me and I’d like to invite you to join our advisory board. We have a few key initiatives where your marketing skills could be a big help...`}
        >
        </TextField>

        <div style={classes.flexContainer}>
          {isLoading ? (
            <div style={classes.modalLoadingContainer}>
              <CircularProgress />
            </div>
          ) : (
            <div style={{
              ...classes.flexContainer,
              alignItems: "center",
              justifyContent: "center",
              marginTop: "21px",
              columnGap: isSM ? "4px" : "13px"
            }}>
              <div style={classes.inviteButtonContainer}>
                <ACButton
                  id={"sendItjWithNote"}
                  color="primary"
                  variant={"outlined"}
                  width={isSM ? '9.1rem' : '12rem'}
                  style={{
                    height: '30px',
                    backgroundColor: invitation?.current?.value ? '#0F78FD' : "#ACC5FC",
                    paddingTop: 15,
                    paddingBottom: 15,
                    textTransform: 'none',
                    fontFamily: 'Poppins',
                    fontWeight: 700,
                    fontSize: 15,
                    height: 40,
                    borderRadius: 30,
                    color: "#FFF"
                  }}
                  onClick={() => onConfirm()}
                  disabled={!invitation?.current?.value}
                >
                  <Typography
                    variant={isSM ? 'caption_old_font' : 'body2_old_font'}
                  >
                    {
                      "Send"
                    }
                  </Typography>
                </ACButton>
              </div>
              <div style={classes.inviteButtonContainer}>
                <ACButton
                  id={"sendItjWithoutNote"}
                  variant={"outlined"}
                  color="primary"
                  width={isSM ? '9.1rem' : '12rem'}
                  style={{
                    height: '30px',
                    backgroundColor: '#FFF',
                    paddingTop: 15,
                    paddingBottom: 15,
                    textTransform: 'none',
                    fontFamily: 'Poppins',
                    fontWeight: 700,
                    fontSize: 15,
                    height: 40,
                    borderRadius: 30,
                    color: '#0F78FD',
                    border: '2px solid #0F78FD',
                    borderColor: '#0F78FD !important',
                  }}
                  onClick={() => {
                    setInvitationNote("");
                    onConfirm()
                  }}
                >
                  <Typography
                    variant={isSM ? 'caption_old_font' : 'body2_old_font'}
                  >
                    {
                      "Send without note"
                    }
                  </Typography>
                </ACButton>
              </div>
              <Typography
                style={{
                  color: "hsla(221, 92%, 58%, 1)",
                  letterSpacing: "-0.43px",
                  lineBreak: "24px",
                  fontWeight: "500",
                  marginTop: "16px",
                  marginBottom: "40px"
                }}
              >
                {
                  "Invitations with notes are more likely to be accepted!"
                }
              </Typography>
            </div>
          )}
        </div>
      </Paper>
    )
  }

  return (
    <div style={classes.mask}>
      <ACSnackbar
        open={snackbarSuccess}
        text={snackMessage ? t(snackMessage) : ''}
        severity={SnackbarType.SUCCESS}
        onClose={() => setSnackbarSuccess(false)}
        autoHideDuration={6000}
      />
      <ACSnackbar
        open={snackbarWarning}
        text={snackMessage ? t(snackMessage) : ''}
        severity={SnackbarType.WARNING}
        onClose={() => setSnackbarWarning(false)}
        autoHideDuration={6000}
      />
      <div style={classes.container}>
        <Treatment
          userId={accountDetails?.id}
          splitNames={featureFlags.itj_notes}
          updateOnSdkTimedout
          evaluatedComponent={newInvitation()}
          conditionFlag
          conditionFailedComponent={oldInvitation()}
        />
      </div>
    </div>
  );
}

export default InviteToJoinConfirmationModal;
