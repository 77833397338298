import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAccount } from './../../../contexts/Account';
import { Dialog, Slide, useMediaQuery } from '@mui/material';
import { Clear } from '@mui/icons-material';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import eventBus, { eventBusValues } from './../../../eventBus';
import { getCompanyRecurlyPlan } from './../../../services/company_services';
import { getAdvisorPlanUpgrades } from './../../../services/advisor_services';
import FreeTrialModalAdvisor from './advisorFreeTrialModal';
import { sendFSEventUpgradeViewed } from '../../../services/FullStory';

const AdvisorFreeTrialModal = () => {
  const { accountDetails, type, currentPlan, currentUserInfo } = useAccount();
  const location = useLocation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [openModal, setOpenModal] = useState(false);
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [monthlyUpgradeCrowdsourced, setMonthlyUpgradeCrowdsourced] =
    useState('');
  const [monthlyUpgradeTraditional, setMonthlyUpgradeTraditional] =
    useState('');
  const [annualUpgradeCrowdsourced, setAnnualUpgradeCrowdsourced] =
    useState('');
  const [annualUpgradeTraditional, setAnnualUpgradeTraditional] = useState('');
  const [userTier, setUserTier] = useState('');
  const [monthlyUpgrade1Tier, setMonthlyUpgrade1Tier] = useState('');
  const [monthlyUpgrade2Tier, setMonthlyUpgrade2Tier] = useState('');
  const [annualUpgrade1Tier, setAnnualUpgrade1Tier] = useState('');
  const [annualUpgrade2Tier, setAnnualUpgrade2Tier] = useState('');
  const [monthlyUpgrade1BillingCycle, setMonthlyUpgrade1BillingCycle] =
    useState('');
  const [monthlyUpgrade2BillingCycle, setMonthlyUpgrade2BillingCycle] =
    useState('');
  const [annualUpgrade1BillingCycle, setAnnualUpgrade1BillingCycle] =
    useState('');
  const [annualUpgrade2BillingCycle, setAnnualUpgrade2BillingCycle] =
    useState('');
  const [monthlyUpgrade1Calendar, setMonthlyUpgrade1Calendar] = useState('');
  const [monthlyUpgrade2Calendar, setMonthlyUpgrade2Calendar] = useState('');
  const [annualUpgrade1Calendar, setAnnualUpgrade1Calendar] = useState('');
  const [annualUpgrade2Calendar, setAnnualUpgrade2Calendar] = useState('');
  const [currentPlanUpgrade, setCurrentPlanUpgrade] = useState('');
  const [currentPlanTier, setCurrentPlanTier] = useState('');
  const [currentPlanCalendar, setCurrentPlanCalendar] = useState('');
  const [onboardingCalendar, setOnboardingCalendar] = useState('');
  const [planData, setPlanData] = useState({ plan: 'mo', option: 1, data: '' });
  const [hideBack, setHideBack] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [annualChecked, setAnnualChecked] = useState(false);
  const [isUpgrading, setIsUpgrading] = useState(false);

  const sendFSViewedEvent = (upgradeAttempted = false) => {
    sendFSEventUpgradeViewed({
      accountDetails,
      currentPlan: currentPlan,
      currentUserInfo,
      source: location?.state?.fromBanner ? 'banner' : 'gate',
      type,
      upgradeType: 'free_trial',
      upgradeAttempted,
    });
  };

  const handleAnnualChange = (checked) => {
    setAnnualChecked(checked);
  };
  const handleContinue = () => {
    setStep1(false);
  };

  const handleContinueStep3 = (hideBackOption, planCodeCalendar) => {
    if (hideBackOption) {
      setHideBack(true);
      setOnboardingCalendar(planCodeCalendar);
    }
    setStep1(false);
    setStep2(false);
    setStep3(true);
  };

  const handleBack = () => {
    setHideBack(false);
    setStep1(true);
    setStep2(false);
    setStep3(false);
  };

  const handleUpgradeComplete = () => {
    if (pendingAction) {
      pendingAction();
      setPendingAction(null);
    }
  };

  function handleClose() {
    if (isUpgrading) {
      return;
    }
    if (currentPlanUpgrade) {
      setOpenModal(false);
      setStep1(false);
      setStep2(true);
      setStep3(false);
      setHideBack(true);
    } else {
      setOpenModal(false);
      setStep1(true);
      setStep2(false);
      setStep3(false);
      setHideBack(false);
      if (step2) {
        handleUpgradeComplete();
      }
    }
  }

  const handleOptionChange = (plan, option, data) => {
    if (annualChecked) {
      if (option === 2) {
        setPlanData({ plan: 'yr', option: 2, data: annualUpgradeTraditional });
      } else {
        setPlanData({ plan: 'yr', option: 1, data: annualUpgradeCrowdsourced });
      }
    } else {
      if (option === 1) {
        setPlanData({
          plan: 'mo',
          option: 1,
          data: monthlyUpgradeCrowdsourced,
        });
      } else {
        setPlanData({ plan: 'mo', option: 2, data: monthlyUpgradeTraditional });
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const planResponses = await getCompanyRecurlyPlan({
          recurlyPlanCode: accountDetails.recurlyPlanCode,
        });

        const {
          b2bFreeTrialMonthlyUpgrade1,
          b2bFreeTrialMonthlyUpgrade2,
          b2bFreeTrialAnnualUpgrade1,
          b2bFreeTrialAnnualUpgrade2,
          b2bOnboardingCalendar,
        } = planResponses.data.getRecurlyPlan;
        const { tier } = planResponses.data.getRecurlyPlan.salesforceValues;

        if (
          !b2bFreeTrialMonthlyUpgrade1 ||
          !b2bFreeTrialMonthlyUpgrade2 ||
          !b2bFreeTrialAnnualUpgrade1 ||
          !b2bFreeTrialAnnualUpgrade2
        ) {
          setStep1(false);
          setStep2(true);
          setStep3(false);
          setHideBack(true);
          const planResponsesCurrent = await getAdvisorPlanUpgrades({
            RECURLY_PLAN_CODE: accountDetails.recurlyPlanCode,
          });

          setCurrentPlanUpgrade(
            planResponsesCurrent.data.getFreeTrialUpgrades[0]
          );
          setCurrentPlanTier(tier);
          setCurrentPlanCalendar(b2bOnboardingCalendar);
        }
        if (tier) {
          setUserTier(tier);
        }
        if (b2bFreeTrialMonthlyUpgrade1) {
          const planResponsesMonthCrowdsourced = await getAdvisorPlanUpgrades({
            RECURLY_PLAN_CODE: b2bFreeTrialMonthlyUpgrade1,
          });
          const getSalesForcesValues = await getCompanyRecurlyPlan({
            recurlyPlanCode: b2bFreeTrialMonthlyUpgrade1,
          });

          setMonthlyUpgrade1Calendar(
            getSalesForcesValues.data.getRecurlyPlan.b2bOnboardingCalendar
          );

          setMonthlyUpgrade1Tier(
            getSalesForcesValues.data.getRecurlyPlan.salesforceValues.tier
          );
          setMonthlyUpgrade1BillingCycle(
            getSalesForcesValues.data.getRecurlyPlan.salesforceValues
              .billingCycle
          );
          setMonthlyUpgradeCrowdsourced(
            planResponsesMonthCrowdsourced.data.getFreeTrialUpgrades[0]
          );
        }
        if (b2bFreeTrialAnnualUpgrade1) {
          const planResponsesAnnualCrowdsourced = await getAdvisorPlanUpgrades({
            RECURLY_PLAN_CODE: b2bFreeTrialAnnualUpgrade1,
          });
          const getSalesForcesValues = await getCompanyRecurlyPlan({
            recurlyPlanCode: b2bFreeTrialAnnualUpgrade1,
          });

          setAnnualUpgrade1Calendar(
            getSalesForcesValues.data.getRecurlyPlan.b2bOnboardingCalendar
          );
          setAnnualUpgrade1Tier(
            getSalesForcesValues.data.getRecurlyPlan.salesforceValues.tier
          );
          setAnnualUpgrade1BillingCycle(
            getSalesForcesValues.data.getRecurlyPlan.salesforceValues
              .billingCycle
          );
          setAnnualUpgradeCrowdsourced(
            planResponsesAnnualCrowdsourced.data.getFreeTrialUpgrades[0]
          );
        }
        if (b2bFreeTrialMonthlyUpgrade2) {
          const planResponsesMonthTraditional = await getAdvisorPlanUpgrades({
            RECURLY_PLAN_CODE: b2bFreeTrialMonthlyUpgrade2,
          });
          const getSalesForcesValues = await getCompanyRecurlyPlan({
            recurlyPlanCode: b2bFreeTrialMonthlyUpgrade2,
          });
          setMonthlyUpgrade2Calendar(
            getSalesForcesValues.data.getRecurlyPlan.b2bOnboardingCalendar
          );
          setMonthlyUpgrade2Tier(
            getSalesForcesValues.data.getRecurlyPlan.salesforceValues.tier
          );
          setMonthlyUpgrade2BillingCycle(
            getSalesForcesValues.data.getRecurlyPlan.salesforceValues
              .billingCycle
          );
          setMonthlyUpgradeTraditional(
            planResponsesMonthTraditional.data.getFreeTrialUpgrades[0]
          );
        }
        if (b2bFreeTrialAnnualUpgrade2) {
          const planResponsesAnnualTraditional = await getAdvisorPlanUpgrades({
            RECURLY_PLAN_CODE: b2bFreeTrialAnnualUpgrade2,
          });
          const getSalesForcesValues = await getCompanyRecurlyPlan({
            recurlyPlanCode: b2bFreeTrialAnnualUpgrade2,
          });
          setAnnualUpgrade2Calendar(
            getSalesForcesValues.data.getRecurlyPlan.b2bOnboardingCalendar
          );
          setAnnualUpgrade2Tier(
            getSalesForcesValues.data.getRecurlyPlan.salesforceValues.tier
          );
          setAnnualUpgrade2BillingCycle(
            getSalesForcesValues.data.getRecurlyPlan.salesforceValues
              .billingCycle
          );
          setAnnualUpgradeTraditional(
            planResponsesAnnualTraditional.data.getFreeTrialUpgrades[0]
          );
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    }
    eventBus.on(eventBusValues.triggerCompanyUpgradeAction, (data) => {
      if (data && data.planCode === 'Traditional') {
        handleContinue();
        setIsFirstLoad(false);
        setHideBack(true);
      }
      if (data && data.planCode === 'Crowdsourced') {
        handleContinue();
        setIsFirstLoad(false);
        setHideBack(true);
      }
      if (data && data.action) {
        setPendingAction(() => data.action);
      }
      setOpenModal(true);
    });

    const profileParam = new URLSearchParams(location.search).get(
      'b2b_freetrial_upgrade_popup'
    );
    if (profileParam === 'true') {
      setOpenModal(true);
    }

    fetchData();
    return () => {
      eventBus.remove(eventBusValues.triggerCompanyUpgradeAction);
    };
  }, []);

  return (
    <>
      {type === 'company' ? (
        <div>
          <Dialog
            open={openModal}
            fullScreen={isSM}
            disableBackdropClick={isUpgrading}
            disableEscapeKeyDown={isUpgrading}
            onClose={() => {
              handleClose();
              sendFSViewedEvent();
            }}
            PaperProps={{
              id: 'companyFreemiumUpgradeModal',
              sx: {
                borderRadius: isSM ? '0px' : '15px',
                boxShadow: '0 5px 20px rgba(0, 0, 0, 0.07)',
                height: { xxs: '100%', sm: '742px' },
                width: { xxs: '100%', sm: '920px' },
              },
            }}
          >
            {isSM && (
              <div
                style={{
                  position: 'absolute',
                  top: '20px',
                  right: '20px',
                  cursor: 'pointer',
                }}
              >
                <Clear
                  onClick={() => {
                    handleClose();
                    sendFSViewedEvent();
                  }}
                />
              </div>
            )}

            {isFirstLoad && !step3 && !currentPlanUpgrade ? (
              <div>
                <Step1
                  isUserSegment={accountDetails.userSegment}
                  onContinue={() => {
                    handleContinue();
                    setHideBack(false);
                    setIsFirstLoad(false);
                  }}
                  onOptionChange={handleOptionChange}
                  setOpenModal={setOpenModal}
                  closeModal={handleClose}
                  month1={monthlyUpgradeCrowdsourced}
                  month2={monthlyUpgradeTraditional}
                  annual1={annualUpgradeCrowdsourced}
                  annual2={annualUpgradeTraditional}
                  annualChecked={annualChecked}
                  onAnnualChange={handleAnnualChange}
                  userTier={userTier}
                  tierMonth1={monthlyUpgrade1Tier}
                  tierMonth2={monthlyUpgrade2Tier}
                  tierAnnual1={annualUpgrade1Tier}
                  tierAnnual2={annualUpgrade2Tier}
                  billingCycleMonth1={monthlyUpgrade1BillingCycle}
                  billingCycleMonth2={monthlyUpgrade2BillingCycle}
                  billingCycleAnnual1={annualUpgrade1BillingCycle}
                  billingCycleAnnual2={annualUpgrade2BillingCycle}
                  handleContinueStep3={handleContinueStep3}
                  monthlyUpgrade1Calendar={monthlyUpgrade1Calendar}
                  monthlyUpgrade2Calendar={monthlyUpgrade2Calendar}
                  annualUpgrade1Calendar={annualUpgrade1Calendar}
                  annualUpgrade2Calendar={annualUpgrade2Calendar}
                />
              </div>
            ) : (
              <Slide direction="right" in={step1} mountOnEnter unmountOnExit>
                <div>
                  <Step1
                    isUserSegment={accountDetails.userSegment}
                    onContinue={handleContinue}
                    onOptionChange={handleOptionChange}
                    setOpenModal={setOpenModal}
                    closeModal={handleClose}
                    month1={monthlyUpgradeCrowdsourced}
                    month2={monthlyUpgradeTraditional}
                    annual1={annualUpgradeCrowdsourced}
                    annual2={annualUpgradeTraditional}
                    tierMonth1={monthlyUpgrade1Tier}
                    tierMonth2={monthlyUpgrade2Tier}
                    tierAnnual1={annualUpgrade1Tier}
                    tierAnnual2={annualUpgrade2Tier}
                    billingCycleMonth1={monthlyUpgrade1BillingCycle}
                    billingCycleMonth2={monthlyUpgrade2BillingCycle}
                    billingCycleAnnual1={annualUpgrade1BillingCycle}
                    billingCycleAnnual2={annualUpgrade2BillingCycle}
                    annualChecked={annualChecked}
                    onAnnualChange={handleAnnualChange}
                    userTier={userTier}
                    handleContinueStep3={handleContinueStep3}
                    monthlyUpgrade1Calendar={monthlyUpgrade1Calendar}
                    monthlyUpgrade2Calendar={monthlyUpgrade2Calendar}
                    annualUpgrade1Calendar={annualUpgrade1Calendar}
                    annualUpgrade2Calendar={annualUpgrade2Calendar}
                  />
                </div>
              </Slide>
            )}
            {currentPlanUpgrade ? (
              <Slide
                direction="left"
                in={!step1 && step2 && !step3}
                mountOnEnter
                unmountOnExit
                timeout={800}
              >
                <div>
                  <Step2
                    planData={planData}
                    goBack={handleBack}
                    closeModal={handleClose}
                    hideBack={hideBack}
                    pendingAction={pendingAction}
                    handleContinueStep3={handleContinueStep3}
                    setSelectedPlanFinal={setSelectedPlan}
                    currentPlanUpgrade={currentPlanUpgrade}
                    currentPlanCalendar={currentPlanCalendar}
                    currentPlanTier={currentPlanTier}
                    isUpgrading={isUpgrading}
                    setIsUpgrading={setIsUpgrading}
                  />
                </div>
              </Slide>
            ) : (
              <Slide
                direction="left"
                in={!step1 && !step2 && !step3}
                mountOnEnter
                unmountOnExit
                timeout={800}
              >
                <div>
                  <Step2
                    planData={planData}
                    goBack={handleBack}
                    closeModal={handleClose}
                    month1={monthlyUpgradeCrowdsourced}
                    month2={monthlyUpgradeTraditional}
                    annual1={annualUpgradeCrowdsourced}
                    annual2={annualUpgradeTraditional}
                    hideBack={hideBack}
                    pendingAction={pendingAction}
                    handleContinueStep3={handleContinueStep3}
                    setSelectedPlanFinal={setSelectedPlan}
                    annualChecked={annualChecked}
                    tierMonth1={monthlyUpgrade1Tier}
                    tierMonth2={monthlyUpgrade2Tier}
                    tierAnnual1={annualUpgrade1Tier}
                    tierAnnual2={annualUpgrade2Tier}
                    monthlyUpgrade1Calendar={monthlyUpgrade1Calendar}
                    monthlyUpgrade2Calendar={monthlyUpgrade2Calendar}
                    annualUpgrade1Calendar={annualUpgrade1Calendar}
                    annualUpgrade2Calendar={annualUpgrade2Calendar}
                    isUpgrading={isUpgrading}
                    setIsUpgrading={setIsUpgrading}
                  />
                </div>
              </Slide>
            )}

            <Slide
              direction="left"
              in={!step1 && !step2 && step3}
              mountOnEnter
              unmountOnExit
              timeout={800}
            >
              <div style={{ marginTop: '30px' }}>
                <Step3
                  goBack={handleBack}
                  hideBack={hideBack}
                  planCode={onboardingCalendar}
                />
              </div>
            </Slide>
          </Dialog>
        </div>
      ) : (
        <FreeTrialModalAdvisor />
      )}
    </>
  );
};

export default AdvisorFreeTrialModal;
