import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from 'react';
import TagManager from 'react-gtm-module';
import PropTypes from 'prop-types';
import {
  getAndFormatTags,
  getCurrentUserInfo,
  getImpersonateDetails,
  getUserAccountContext,
  signOut,
} from '../services/Authentication';
import {
  advisorFreeTrialEndsOnSubscription,
  companyFreeTrialEndsOnSubscription,
  companyChangeBoardSeatasAvailableSubscription,
  companyOnAddResponseSubscription,
  companyOnAddReplySubscription,
  advisorOnAddResponseSubscription,
  advisorOnAddReplySubscription,
  onLikePostSubscription,
  onLikeResponseSubscription,
  onLikeReplySubscription,
  onNewMessageSubscription,
  onNewConversationSubscription,
} from '../services/subscriptions';
import { isDateExpired } from '../services/date';
import { AccountType } from '../services/utils/types';
import { GetAccountContextResponses } from '../services/Authentication/constants';
import {
  updateHideTrustPilotCall,
  getIntercomSettingsCall,
} from '../services/Backend/account';
import {
  getCompanyRecurlyPlan,
  getMyAdvisors,
} from '../services/company_services';
import { getAdvisorRecurlyPlan } from '../services/advisor_services';
import eventBus, { eventBusValues } from '../eventBus';
import { getConversations } from '../services/conversation_services';
import { modifyAllConversations } from '../utils/conversation';

export const ActionTypes = {
  SIGN_OUT: 'SIGN_OUT',
  FREE_TRIAL_CHANGE: 'FREE_TRIAL_CHANGE',
  COMPANY_ONBOARDING_COMPLETED: 'COMPANY_ONBOARDING_COMPLETED',
  COMPANY_TERMS_OF_SERVICE_ACCEPTED: 'COMPANY_TERMS_OF_SERVICE_ACCEPTED',
  ADVISOR_TERMS_OF_SERVICE_ACCEPTED: 'ADVISOR_TERMS_OF_SERVICE_ACCEPTED',
  IMPERSONATE: 'IMPERSONATE',
  AUTHENTICATE: 'AUTHENTICATE',
  SWITCH_ACCOUNT_TYPE: 'SWITCH_ACCOUNT_TYPE',
  ADVISOR_RELATED_COMPANIES: 'ADVISOR_RELATED_COMPANIES',
  ACCOUNT_INFORMATION_EDITED: 'ACCOUNT_INFORMATION_EDITED',
  SEATS_AVAILABLE_INFORMATION_EDITED: 'SEATS_AVAILABLE_INFORMATION_EDITED',
  COMPANY_CONTACT_INFORMATION_EDITED: 'COMPANY_CONTACT_INFORMATION_EDITED',
  COMPANY_CONTACTS_EDITED: 'COMPANY_CONTACTS_EDITED',
  SET_USER_PREFERENCES: 'SET_USER_PREFERENCES',
  SET_TAGS: 'SET_TAGS',
  UPDATE_TRUST_PILOT: 'UPDATE_TRUST_PILOT',
  UPDATE_SAVED_ADVISORS: 'UPDATE_SAVED_ADVISORS',
  MESSAGES_MODIFIED: 'MESSAGES_MODIFIED',
  UNREAD_MESSAGES_MODIFIED: 'UNREAD_MESSAGES_MODIFIED',
  MESSAGES_READ: 'MESSAGES_READ',
  FREEMIUM_UPGRADE: 'FREEMIUM_UPGRADE',
};

const AccountContext = createContext({});
const freeTrialInterval = null;
const INITIAL_STATE = {
  type: null,
  isAuthenticated: false,
  isImpersonated: false,
  companyOnboardingCompleted: false,
  currentUserInfo: null,
  accountDetails: {},
  currentPlan: {
    salesforceValues: {},
  },
  isAcPlus: false,
  isFreeTrial: false,
  isFreemium: false,
  asLoggedUserDetails: {
    userId: '',
    advisorRelatedCompanies: {},
    advisorOnboardingUrlRedirect: null,
  },
  userPreferences: null,
  savedAdvisors: [],
  conversations: [],
  unreadMessages: 0,
  tags: {
    industries: [],
    positions: [],
    skills: [],
  },
};
const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SWITCH_ACCOUNT_TYPE:
      return {
        ...state,
        accountDetails: action.payload.accountDetails,
        type: action.payload.type,
      };
    case ActionTypes.AUTHENTICATE:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        currentUserInfo: action.payload.currentUserInfo,
        accountDetails: action.payload.accountDetails,
        isFreemium: action.payload.isFreemium,
        isAcPlus: action.payload.isAcPlus,
        conversations: action.payload.conversations,
        currentPlan: action.payload.currentPlan,
        asLoggedUserDetails: {
          ...state.asLoggedUserDetails,
          userId: action.payload.asLoggedUserId,
          advisorOnboardingUrlRedirect: action.payload.asLoggedUrlRedirect,
        },
      };
    case ActionTypes.IMPERSONATE:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        isImpersonated: action.payload.isImpersonated,
        currentUserInfo: action.payload.currentUserInfo,
        accountDetails: action.payload.accountDetails,
        userPreferences: action.payload.userPreferences,
        isFreeTrial: action.payload.isFreeTrial,
        isFreemium: action.payload.isFreemium,
        isAcPlus: action.payload.isAcPlus,
        currentPlan: action.payload.currentPlan,
        asLoggedUserDetails: {
          ...state.asLoggedUserDetails,
          userId: action.payload.asLoggedUserId,
        },
      };
    case ActionTypes.COMPANY_ONBOARDING_COMPLETED:
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          onboardingStatus: action.payload.onboardingStatus,
        },
      };
    case ActionTypes.COMPANY_TERMS_OF_SERVICE_ACCEPTED: {
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          contacts: action.payload.contacts,
        },
      };
    }
    case ActionTypes.ADVISOR_TERMS_OF_SERVICE_ACCEPTED: {
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          acceptTermsOfService: true,
        },
      };
    }
    case ActionTypes.COMPANY_CONTACT_INFORMATION_EDITED: {
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          contacts: action.payload.contacts,
          userData: action.payload.userData,
        },
      };
    }
    case ActionTypes.COMPANY_CONTACTS_EDITED: {
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          contacts: action.payload.contacts,
        },
      };
    }

    case ActionTypes.SEATS_AVAILABLE_INFORMATION_EDITED: {
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          boardSeatsAvailable: action.payload.boardSeatsAvailable,
        },
      };
    }
    case ActionTypes.ACCOUNT_INFORMATION_EDITED: {
      if (action.payload.removeFreeTrial) {
        return {
          ...state,
          accountDetails: action.payload.newAccountDetails,
          isFreeTrial: false,
        };
      } else {
        return {
          ...state,
          accountDetails: action.payload.newAccountDetails,
        };
      }
    }
    case ActionTypes.ADVISOR_RELATED_COMPANIES:
      return {
        ...state,
        currentUserInfo: action.payload.currentUserInfo,
        asLoggedUserDetails: {
          ...state.asLoggedUserDetails,
          advisorRelatedCompanies: action.payload.advisorRelatedCompanies,
        },
      };
    case ActionTypes.SET_USER_PREFERENCES:
      return {
        ...state,
        userPreferences: action.payload,
      };
    case ActionTypes.SET_TAGS:
      return {
        ...state,
        tags: action.payload,
      };
    case ActionTypes.SET_FREE_TRIAL:
      return {
        ...state,
        isFreeTrial: false,
      };
    case ActionTypes.UPDATE_TRUST_PILOT:
      return {
        ...state,
        userPreferences: {
          ...state,
          hideTrustPilot: action.payload.hideTrustPilot,
        },
      };
    case ActionTypes.FREE_TRIAL_CHANGE:
      return {
        ...state,
        isFreeTrial: action.payload,
      };
    case ActionTypes.FREEMIUM_UPGRADE:
      return {
        ...state,
        isFreeTrial: false,
        isFreemium: false,
        accountDetails: {
          ...state.accountDetails,
          recurlyPlanCode: action.payload.recurlyPlanCode,
          opportunityStage: action.payload.opportunityStage,
        },
      };
    case ActionTypes.UPDATE_SAVED_ADVISORS:
      return {
        ...state,
        savedAdvisors: action.payload,
      };
    case ActionTypes.MESSAGES_MODIFIED:
      return {
        ...state,
        conversations: action.payload,
      };
    case ActionTypes.UNREAD_MESSAGES_MODIFIED:
      return {
        ...state,
        unreadMessages: action.payload,
      };
    case ActionTypes.MESSAGES_READ:
      return {
        ...state,
        conversations: action.payload.conversations,
        unreadMessages: action.payload.unreadMessages,
      };
    case ActionTypes.SIGN_OUT:
      return { ...INITIAL_STATE };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const useAccountActionsWithStore = (initialState = INITIAL_STATE) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const freeTrialListener = useCallback((event) => {
    if (event && event.value && event.value.data) {
      const { freeTrialEndsOn } = event.value.data.onChangeFreeTrialEndsOn
        ? event.value.data.onChangeFreeTrialEndsOn
        : event.value.data.onChangeCompanyRecurlyPlanCode;
      if (freeTrialInterval) {
        clearInterval(freeTrialInterval);
      }

      dispatch({
        type: ActionTypes.FREE_TRIAL_CHANGE,
        payload: !isDateExpired(freeTrialEndsOn),
      });
    }
  }, []);

  const changeSeatsListener = useCallback((event) => {
    if (
      event &&
      event.value &&
      event.value.data &&
      event.value.data.onCompanyChangeBoardSeatasAvailable
    ) {
      const contacts =
        event.value.data.onCompanyChangeBoardSeatasAvailable.contacts;
      const boardSeatsAvailable =
        event.value.data.onCompanyChangeBoardSeatasAvailable
          .boardSeatsAvailable;

      if (contacts && contacts.length > 0) {
        dispatch({
          type: ActionTypes.COMPANY_CONTACTS_EDITED,
          payload: { contacts },
        });
      }
      if (boardSeatsAvailable || boardSeatsAvailable == 0) {
        dispatch({
          type: ActionTypes.SEATS_AVAILABLE_INFORMATION_EDITED,
          payload: { boardSeatsAvailable },
        });
      }
    }
  }, []);

  function unauthenticate() {
    dispatch({
      type: ActionTypes.AUTHENTICATE,
      payload: { isAuthenticated: false },
    });
  }

  /**
   * Set userAccountContext to the Store
   * @param {Function} authenticatedCallBack - function to execute in case there is an exception
   * @param {String} accountType - the accountType in case there is dual experience
   */
  async function setUserAccountContext({ authenticatedCallBack, accountType }) {
    try {
      const responses = await getUserAccountContext({ accountType });
      const { currentUserInfo, accountDetails, additionalDetails } =
        responses.returnObject;

      if (!currentUserInfo || !accountDetails || !additionalDetails) {
        if (authenticatedCallBack) {
          authenticatedCallBack(error);
        }
        unauthenticate();
        return;
      }

      let userPreferences = additionalDetails.preferences;
      try {
        if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
          const tagManagerArgs = {
            gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
            dataLayer: {
              event: 'start_pw',
              pw_user_email: currentUserInfo.attributes.email,
            },
          };

          TagManager.initialize(tagManagerArgs);
          console.log('Google Tag Manager initialize');
        }
      } catch (error) {
        console.log('error with tag manager');
        console.log(error);
      }

      const planCodeCall =
        currentUserInfo.accountType === AccountType.ADVISOR
          ? getAdvisorRecurlyPlan
          : getCompanyRecurlyPlan;
      const { data } = await planCodeCall({
        recurlyPlanCode: accountDetails.recurlyPlanCode,
      });
      const planCodeData = data.getRecurlyPlan;

      let intercomData = await getIntercomSettingsCall({
        ACCOUNT_ID: currentUserInfo.username,
        ACCOUNT_TYPE: currentUserInfo.accountType,
        COMPANY_ID: accountDetails.id,
      });
      const intercomExtra =
        intercomData && intercomData.data
          ? intercomData.data.getIntercomSettings
          : {};
      let userInfo;
      if (currentUserInfo.accountType === AccountType.ADVISOR) {
        userInfo = accountDetails;
      } else {
        userInfo = accountDetails.contacts.find(
          (contact) => contact.id === currentUserInfo.username
        );
      }
      const p2pBoard =
        accountDetails.boards && accountDetails.boards.memberBoards
          ? accountDetails.boards.memberBoards.find(
              (board) => board.boardType === 'P2P'
            )
          : null;

      async function getIntercomSettings() {
        try {
          console.log('Intercom Extra', intercomExtra);
          const settings = {
            email: currentUserInfo.attributes.email,
            user_id: currentUserInfo.username,
            app_id: 'dz5s7g18',
            Name: `${userInfo.givenName} ${userInfo.surName}`,
            Member_Type: currentUserInfo.accountType,
            Registration_Date: userInfo.createdAt,
            First_Name: userInfo.givenName,
            Last_Name: userInfo.surName,
            Recurly_Plan_Code: accountDetails.recurlyPlanCode,
            Free_Trial_Active: accountDetails.freeTrialEndsOn,
            number_of_boards:
              userInfo.boards && userInfo.boards.memberBoards
                ? userInfo.boards.memberBoards.length
                : null,
            total_active_advisors: accountDetails.boardAdvisors
              ? accountDetails.boardAdvisors.length
              : null,
            onboarding_status: accountDetails.onboardingStatus,
            board_meeting_date: accountDetails.upcomingBoardMeeting,
            Billing_Cycle: planCodeData.salesforceValues.billingCycle,
            Boards_Can_Join: planCodeData.boardsCanJoinCount,
            Membership_Tier: planCodeData.salesforceValues.tier,
            last_replied_date: intercomExtra.lastReplyDate,
            last_answered_date: intercomExtra.lastAnswerDate,
            last_posted_date: intercomExtra.lastPostedDate,
            last_invited_date: intercomExtra.lastInvitedDate,
            total_insights_received: intercomExtra.totalInsights,
          };
          console.log('Intercom Settings', settings);

          window.intercomSettings = settings;
          // inner try catch to set Fullstory data
          try {
            // console.log('planCodeData', planCodeData);
            // console.log('accountDetails', accountDetails);
            // console.log('userInfo', userInfo);
            // console.log('userInfo.boards.memberBoards', userInfo.boards.memberBoards);
            const fullstoryParams = {
              displayName: userInfo.displayName || '',
              email: currentUserInfo.attributes.email || '',
              userSegment_str: accountDetails.userSegment || '',
              userType_str: currentUserInfo.accountType || '',
              tier_str: planCodeData.salesforceValues.tier || '',
              plancode_str: planCodeData.recurlyPlanCode || '',
              billingCycle_str: planCodeData.salesforceValues
                ? planCodeData.salesforceValues.billingCycle
                : '',
              isFreemium_bool: planCodeData.isFreemium || false,
              isFreeTrial_bool:
                !isDateExpired(accountDetails.freeTrialEndsOn) || false,
              freeTrialEndsOn_date: accountDetails.freeTrialEndsOn || '',
              registrationDate_date: userInfo.createdAt || new Date(),
              boardsHasJoined_int:
                userInfo.boards && userInfo.boards.memberBoards
                  ? userInfo.boards.memberBoards.filter((mb) => mb.boardType != 'P2P').length
                  : 0,
              numberOfResponsesLeft_int: intercomExtra.responsesLeft || 0,
              numberOfRepliesLeft_int: intercomExtra.replyLeft || 0,
              peerAdvisoryBoard_str: p2pBoard ? p2pBoard.companyName : '',
              totalConversations_int: intercomExtra.totalConversations || 0,
              totalMessagesSent_int: intercomExtra.totalMessagesSent || 0,
              totalMessagesReceived_int:
                intercomExtra.totalMessagesReceived || 0,
              numberOfQuestions_int: intercomExtra.allQuestions || 0,
              numberOfUpdates_int: intercomExtra.allUpdates || 0,
              numberOfBoardMeetings_int: intercomExtra.allBoardMeetings || 0,
              boardMeetingDate_date: accountDetails.upcomingBoardMeeting || '',
              numberOfAdvisorsSaved_int: intercomExtra.totalSavedAdvisors || 0,
              numberOfCompaniesSaved_int:
                intercomExtra.totalSavedCompanies || 0,
              invitesSent_int: intercomExtra.totalInvitesSend || 0,
              invitesReceived_int: intercomExtra.totalInvitesReceived || 0,
              pendingInvites_int: intercomExtra.totalPendingInvitesReceived || 0,
              uniqueProfilesViewed_int: intercomExtra.uniqueViewersCount || 0,
              uniqueProfileViews_int: intercomExtra.uniqueViewsCount || 0,
              featuredAdvisor_bool: accountDetails.featuredAdvisor || false,
              branded_bool: accountDetails.brandFreemium || false,
              advisorKocDateTime_date: accountDetails.kocDateTime || '',
              advisorKocStatus_str: accountDetails.kocStatus || '',
              advisorKocUser_str: accountDetails.kocUser || '',
            };

            console.log('Fullstory Settings', fullstoryParams);
            FS.identify(currentUserInfo.username, fullstoryParams);
          } catch (error) {
            console.log('Error with Fullstory seetings');
            console.log(error);
          }
        } catch (error) {
          console.log('Error with intercom seetings');
          console.log(error);
        }
      }

      getIntercomSettings();

      function setUsetifulConfig() {
        try {
          const configTags = {
            userId: currentUserInfo.username,
            userSegment: accountDetails.userSegment || null,
            planCode: accountDetails.recurlyPlanCode,
            onboardingStatus: accountDetails.onboardingStatus || null,
            freeTrial: !isDateExpired(accountDetails.freeTrialEndsOn),
            billingCycle: planCodeData.salesforceValues.billingCycle || '',
            tier: planCodeData.salesforceValues.tier || '',
            freeTrialEndsOn: accountDetails.freeTrialEndsOn || '',
            boardsHasJoined:
            userInfo.boards && userInfo.boards.memberBoards
            ? userInfo.boards.memberBoards.filter((mb) => mb.boardType != 'P2P').length
            : 0,
            numberOfResponsesLeft: intercomExtra.responsesLeft || 0,
            numberOfRepliesLeft: intercomExtra.replyLeft || 0,
            peerAdvisoryBoard: p2pBoard ? p2pBoard.companyName : '',
            totalConversations: intercomExtra.totalConversations || 0,
            totalMessagesSent: intercomExtra.totalMessagesSent || 0,
            totalMessagesReceived: intercomExtra.totalMessagesReceived || 0,
            numberOfQuestions: intercomExtra.allQuestions || 0,
            numberOfUpdates: intercomExtra.allUpdates || 0,
            numberOfBoardMeetings: intercomExtra.allBoardMeetings || 0,
            boardMeetingDate: accountDetails.upcomingBoardMeeting || '',
            numberOfAdvisorsSaved: intercomExtra.totalSavedAdvisors || 0,
            numberOfCompaniesSaved: intercomExtra.totalSavedCompanies || 0,
            invitesSent: intercomExtra.totalInvitesSend || 0,
            invitesReceived: intercomExtra.totalInvitesReceived || 0,
            pendingInvites: intercomExtra.totalPendingInvitesReceived || 0,
            uniqueProfilesViewed: intercomExtra.uniqueViewersCount || 0,
            uniqueProfileViews: intercomExtra.uniqueViewsCount || 0,
          };

          // let userInfo = accountDetails;

          if (currentUserInfo.accountType === AccountType.ADVISOR) {
            configTags.userType = 'advisorUser';
          } else {
            // userInfo = accountDetails.contacts.find(
            //   (contact) => contact.id === currentUserInfo.username
            // );
            configTags.userType = userInfo.isBoardOwner
              ? 'boardOwner'
              : 'companyUser';
            configTags.displayName = accountDetails.displayName;
            configTags.plannedUsage = accountDetails.plannedUsage || null;
          }

          configTags.email = userInfo.email;
          configTags.registrationDate = userInfo.createdAt.split('T')[0];
          configTags.firstName = userInfo.givenName;
          configTags.lastName = userInfo.surName;
          configTags.brandFreemium = planCodeData.isFreemium || false;

          console.log('Usetiful Settings', configTags);

          // window.USETIFUL.user.setTags(configTags);
          window.usetifulTags = configTags;
        } catch (error) {
          console.log('Error with Usetiful settings');
          console.log(error);
        }
      }

      setUsetifulConfig();

      if (
        responses.type === GetAccountContextResponses.SUCCESS &&
        currentUserInfo &&
        currentUserInfo.accountType
      ) {
        if (currentUserInfo.accountType === AccountType.ADVISOR) {
          freeTrialListener({
            data: {
              onChangeFreeTrialEndsOn: {
                freeTrialEndsOn: accountDetails.freeTrialEndsOn,
              },
            },
          });
          advisorFreeTrialEndsOnSubscription(
            { id: accountDetails.id },
            freeTrialListener
          );

          const advisorBelongsToCompany = (companyId) => {
            const board = accountDetails.boards.memberBoards.find(
              (advisorBoard) => advisorBoard.id === companyId
            );
            if (board) {
              return true;
            }
            return false;
          };

          advisorOnAddResponseSubscription((event) => {
            try {
              const board = accountDetails.boards.memberBoards.find(
                (advisorBoard) =>
                  advisorBoard.id ===
                  event.value.data.onAddResponse.postCompanyId
              );
              if (board) {
                eventBus.dispatch(
                  `${eventBusValues.onAddResponse}${event.value.data.onAddResponse.postId}`,
                  event.value.data.onAddResponse
                );
              }
            } catch (error) {
              console.log(error);
            }
          });

          advisorOnAddReplySubscription((event) => {
            try {
              const board = accountDetails.boards.memberBoards.find(
                (advisorBoard) =>
                  advisorBoard.id === event.value.data.onAddReply.postCompanyId
              );
              if (board) {
                eventBus.dispatch(
                  `${eventBusValues.onAddReply}${event.value.data.onAddReply.postId}`,
                  event.value.data.onAddReply
                );
              }
            } catch (error) {
              console.log(error);
            }
          });

          onLikePostSubscription(({ value }) => {
            const event = value.data.onLikePost;
            if (advisorBelongsToCompany(event.companyId)) {
              eventBus.dispatch(
                `${eventBusValues.onLikePost}${event.id}`,
                event
              );
            }
          });

          onLikeResponseSubscription(({ value }) => {
            const event = value.data.onLikeResponse;
            if (advisorBelongsToCompany(event.postCompanyId)) {
              eventBus.dispatch(
                `${eventBusValues.onLikeResponse}${event.postId}`,
                event
              );
            }
          });

          onLikeReplySubscription(({ value }) => {
            const event = value.data.onLikeReply;
            if (advisorBelongsToCompany(event.postCompanyId)) {
              eventBus.dispatch(
                `${eventBusValues.onLikeReply}${event.postId}`,
                event
              );
            }
          });

          // const advisorMemberBoards = await getMemberAndAppliedBoards({
          //   advisorId: accountDetails.id,
          // });
          // const mocK = {
          //   memberBoards: [
          //     {
          //       id: 'deba43f3-73dc-4f4e-a9a6-2d1e3d1c0311',
          //       companyName: 'Company Test',
          //       slug: 'companyTest',
          //     },
          //   ],
          // };

          dispatch({
            type: ActionTypes.ADVISOR_RELATED_COMPANIES,
            payload: {
              advisorRelatedCompanies: accountDetails.boards,
            },
          });
        }
        if (currentUserInfo.accountType === AccountType.COMPANY) {
          accountDetails.userData = accountDetails.contacts.find(
            (contact) => contact.id === currentUserInfo.username
          );
          userPreferences = accountDetails.userData.preferences;

          freeTrialListener({
            data: {
              onChangeFreeTrialEndsOn: {
                freeTrialEndsOn: accountDetails.freeTrialEndsOn,
              },
            },
          });

          companyChangeBoardSeatasAvailableSubscription(
            { id: accountDetails.id },
            changeSeatsListener
          );

          companyFreeTrialEndsOnSubscription(
            { id: accountDetails.id },
            freeTrialListener
          );

          companyOnAddResponseSubscription(
            { id: accountDetails.id },
            (event) => {
              eventBus.dispatch(
                `${eventBusValues.onAddResponse}${event.value.data.onAddResponse.postId}`,
                event.value.data.onAddResponse
              );
            }
          );

          companyOnAddReplySubscription({ id: accountDetails.id }, (event) => {
            eventBus.dispatch(
              `${eventBusValues.onAddReply}${event.value.data.onAddReply.postId}`,
              event.value.data.onAddReply
            );
          });

          onLikePostSubscription(({ value }) => {
            const event = value.data.onLikePost;
            if (accountDetails.id === event.companyId) {
              eventBus.dispatch(
                `${eventBusValues.onLikePost}${event.id}`,
                event
              );
            }
          });

          onLikeResponseSubscription(({ value }) => {
            const event = value.data.onLikeResponse;
            if (accountDetails.id === event.postCompanyId) {
              eventBus.dispatch(
                `${eventBusValues.onLikeResponse}${event.postId}`,
                event
              );
            }
          });

          onLikeReplySubscription(({ value }) => {
            const event = value.data.onLikeReply;
            if (accountDetails.id === event.postCompanyId) {
              eventBus.dispatch(
                `${eventBusValues.onLikeReply}${event.postId}`,
                event
              );
            }
          });

          async function getSavedAdvisors() {
            let savedAdvisors = await getMyAdvisors({
              COMPANY_ID: accountDetails.id,
              TYPE: 'savedAdvisors',
              USER_ID: accountDetails.userData.id,
            });
            savedAdvisors = savedAdvisors.data.getMyAdvisors.map(
              (advisor) => advisor.id
            );

            dispatch({
              type: ActionTypes.UPDATE_SAVED_ADVISORS,
              payload: savedAdvisors,
            });
          }
          getSavedAdvisors();
        }
        dispatch({
          type: ActionTypes.SET_USER_PREFERENCES,
          payload: {
            hideTrustPilot: userPreferences.hideTrustPilot,
            isDualAccount: userPreferences.isDualAccount,
          },
        });
        dispatch({
          type: ActionTypes.SWITCH_ACCOUNT_TYPE,
          payload: { type: currentUserInfo.accountType, accountDetails },
        });
        let conversations = [];
        try {
          let conversationsResponse = await getConversations({
            id: currentUserInfo.username,
            accountType: currentUserInfo.accountType,
          });
          conversations = conversationsResponse.data.getConversations;
        } catch (err) {
          console.log(err);
        }

        dispatch({
          type: ActionTypes.AUTHENTICATE,
          payload: {
            accountDetails,
            currentUserInfo,
            conversations,
            isAuthenticated: true,
            isFreemium: planCodeData.isFreemium,
            isAcPlus: planCodeData.isAcPlus,
            asLoggedUserId: currentUserInfo.username,
            asLoggedUrlRedirect: additionalDetails.advisorOnboardingRedirectUrl,
            currentPlan: planCodeData,
          },
        });
        dispatch({
          type: ActionTypes.FREE_TRIAL_CHANGE,
          payload:
            !isDateExpired(accountDetails.freeTrialEndsOn) ||
            planCodeData.isFreemium,
        });
        function modifyUnreadValue() {
          let totalUnread = 0;
          conversations.forEach((conv) => {
            totalUnread += conv.unreadCount;
          });

          dispatch({
            type: ActionTypes.UNREAD_MESSAGES_MODIFIED,
            payload: totalUnread,
          });
        }
        // Limiting the notifications fot TKT-260
        let conversationsSubscriptions = 0;
        conversations.forEach((conversation) => {
          if (conversationsSubscriptions < 90) {
            onNewMessageSubscription(
              { conversationId: conversation.conversationId },
              ({ value }) => {
                const event = value.data.onNewMessage;
                if (event.senderId !== currentUserInfo.username) {
                  modifyAllConversations(event, conversations);
                  eventBus.dispatch(eventBusValues.onNewMessage, event);
                  eventBus.dispatch(
                    `${eventBusValues.onNewMessage}${event.conversationId}`,
                    event
                  );
                } else {
                  eventBus.dispatch(eventBusValues.onSendNewMessage, event);
                }
              }
            );
            conversationsSubscriptions += 1;
          }
        });
        onNewConversationSubscription(
          { receiverId: currentUserInfo.username },
          (conversation) => {
            const newConversation = conversation.value.data.onNewConversation;
            modifyAllConversations(newConversation, conversations);
            eventBus.dispatch(
              eventBusValues.onNewConversation,
              newConversation
            );
            onNewMessageSubscription(
              { conversationId: newConversation.conversationId },
              ({ value }) => {
                const event = value.data.onNewMessage;
                if (event.senderId !== currentUserInfo.username) {
                  modifyAllConversations(event, conversations);
                  eventBus.dispatch(eventBusValues.onNewMessage, event);
                  eventBus.dispatch(
                    `${eventBusValues.onNewMessage}${event.conversationId}`,
                    event
                  );
                }
              }
            );
          }
        );
        modifyUnreadValue();
      } else {
        unauthenticate();
      }
    } catch (error) {
      console.log(error);
      if (authenticatedCallBack) {
        authenticatedCallBack(error);
      }
      unauthenticate();
    }
  }

  return {
    ...state,
    updateHideTrustPilot: async (userId, accountType, hideTrustPilot) => {
      const userPreferences = await updateHideTrustPilotCall({
        userId,
        accountType,
        hideTrustPilot,
      });
      dispatch({
        type: ActionTypes.UPDATE_TRUST_PILOT,
        payload: {
          hideTrustPilot:
            userPreferences.data.updateUserPreferences.hideTrustPilot,
        },
      });
    },
    switchToAccountType: async (accountType) => {
      await setUserAccountContext({ accountType });
    },
    authenticate: async (cb, accountType) => {
      await setUserAccountContext({ authenticatedCallBack: cb, accountType });
      // const tags = await getAndFormatTags();
      // dispatch({
      //   type: ActionTypes.SET_TAGS,
      //   payload: tags,
      // });
      // console.log('Tags Intialize', tags);
    },
    initializeTags: async () => {
      const tags = await getAndFormatTags();
      dispatch({
        type: ActionTypes.SET_TAGS,
        payload: tags,
      });
      console.log('Tags Intialize', tags);
    },
    impersonate: async (userId, userType) => {
      try {
        const accountDetails = await getImpersonateDetails(userId, userType);
        const currentUserInfo = await getCurrentUserInfo();
        const tags = await getAndFormatTags();
        let planCodeData = {
          salesforceValues: {},
        };
        try {
          const planCodeCall =
            currentUserInfo.accountType === AccountType.ADVISOR
              ? getAdvisorRecurlyPlan
              : getCompanyRecurlyPlan;
          const { data } = await planCodeCall({
            recurlyPlanCode: accountDetails.recurlyPlanCode,
          });
          planCodeData = data.getRecurlyPlan;
        } catch (error) {
          console.log('Error getting plan code data');
        }

        if (userType === AccountType.ADVISOR) {
          dispatch({
            type: ActionTypes.ADVISOR_RELATED_COMPANIES,
            payload: {
              advisorRelatedCompanies: accountDetails.boards,
              currentUserInfo,
            },
          });
        }
        if (userType === AccountType.COMPANY) {
          accountDetails.userData = accountDetails.contacts.find(
            (contact) => contact.id === userId
          );
        }
        if (accountDetails) {
          dispatch({
            type: ActionTypes.SWITCH_ACCOUNT_TYPE,
            payload: { type: userType },
          });
          dispatch({
            type: ActionTypes.IMPERSONATE,
            payload: {
              accountDetails,
              currentUserInfo,
              isAuthenticated: true,
              isImpersonated: true,
              asLoggedUserId: userId,
              isFreeTrial:
                !isDateExpired(accountDetails.freeTrialEndsOn) ||
                planCodeData.isFreemium,
              isFreemium: planCodeData.isFreemium,
              isAcPlus: planCodeData.isAcPlus,
              userPreferences: accountDetails.preferences || {},
              currentPlan: planCodeData,
            },
          });
        }
        dispatch({
          type: ActionTypes.SET_TAGS,
          payload: tags,
        });
      } catch (error) {
        dispatch({
          type: ActionTypes.AUTHENTICATE,
          payload: { isImpersonated: false },
        });
      }
    },
    signOut: async () => {
      await signOut();
      dispatch({ type: ActionTypes.SIGN_OUT });
    },
    acceptCompanyTOS: () => {
      const { contacts } = state.accountDetails;
      const contactIndex = contacts.findIndex(
        (contact) => contact.id === state.currentUserInfo.username
      );

      contacts[contactIndex].acceptTermsOfService = true;

      dispatch({
        type: ActionTypes.COMPANY_TERMS_OF_SERVICE_ACCEPTED,
        payload: { contacts },
      });
    },
    acceptAdvisorTOS: () => {
      dispatch({ type: ActionTypes.ADVISOR_TERMS_OF_SERVICE_ACCEPTED });
    },
    setTagsFromPublicEndpoint: (tags) => {
      dispatch({ type: ActionTypes.SET_TAGS, payload: tags });
    },
    editCompanyContactInformation: (editedCompanyContactInformation) => {
      const { contacts } = state.accountDetails;
      const contactIndex = contacts.findIndex(
        (contact) => contact.id === state.asLoggedUserDetails.userId
      );

      const userData = {
        ...contacts[contactIndex],
        ...editedCompanyContactInformation,
      };

      contacts[contactIndex] = userData;

      dispatch({
        type: ActionTypes.COMPANY_CONTACT_INFORMATION_EDITED,
        payload: { contacts, userData },
      });
    },
    upgradeFreemiumAccount: (recurlyPlanCode, opportunityStage) => {
      dispatch({
        type: ActionTypes.FREEMIUM_UPGRADE,
        payload: { recurlyPlanCode, opportunityStage },
      });
    },
    updateReadMessages: (conversations, conversationId) => {
      const updatedConversation = conversations.find(
        (conversation) => conversation.conversationId === conversationId
      );
      if (updatedConversation) {
        const unreadValue = updatedConversation.unreadCount;
        const { unreadMessages } = state;
        updatedConversation.unreadCount = 0;
        eventBus.dispatch(
          `${eventBusValues.onReadConversation}${conversationId}`
        );
        eventBus.dispatch(eventBusValues.onReadConversation, unreadValue);

        dispatch({
          type: ActionTypes.MESSAGES_READ,
          payload: {
            conversations,
            unreadMessages: unreadMessages - unreadValue,
          },
        });
      }
    },
    editAccountInformation: (editedInformation, removeFreeTrial) => {
      const { accountDetails } = state;

      const newAccountDetails = {
        ...accountDetails,
        ...editedInformation,
      };

      dispatch({
        type: ActionTypes.ACCOUNT_INFORMATION_EDITED,
        payload: { newAccountDetails, removeFreeTrial },
      });
    },
    completeCompanyOnboarding: (onboardingStatus) => {
      dispatch({
        type: ActionTypes.COMPANY_ONBOARDING_COMPLETED,
        payload: { onboardingStatus },
      });
    },
  };
};

export const AccountProvider = (props) => {
  const { children } = props;

  return (
    <AccountContext.Provider value={useAccountActionsWithStore()}>
      {children}
    </AccountContext.Provider>
  );
};

AccountProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAccount = () => useContext(AccountContext);
