import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Paper, Typography, useMediaQuery } from '@mui/material';
import CompanyContact from './../Contact/CompanyContact';
import Tag from './../../Tag';
import { isNil } from './../../../services/utils';
import { isDateExpired, formatBoardJoinedDate } from './../../../services/date';
import { AccountType } from './../../../services/utils/types';
import CompanyBoardPreview from './../BoardPreview';
import CompanySlideHeader from './CompanySlideHeader';
import CustomIcon from './../../../icons/customIcon';
import { useAccount } from './../../../contexts/Account';
import DefaultAdvisorAvatar from './../../../icons/user-avatar.svg';
import AcPlusPreview from './../BoardPreview/AcPlusPreview';

const useStyles = (isSM, disabledCompany) => ({
  card: {
    width: '100%',
    overflowY: disabledCompany ? 'hidden' : null,
  },
  companyInformationSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '40px',
    paddingRight: '20px',
    paddingTop: '20px',
    width: '100%',
    paddingBottom: '20px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mainInformationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  companyName: {
    marginTop: '20px',
    marginBottom: '30px',
  },
  tagsTitle: {
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontSize: '20px',
    color: '#232B35',
    marginTop: '30px',
    marginBottom: '15px',
  },
  boardMeetingUpperBarText: {
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    fontSize: '90%',
  },
  generalMargin: {
    marginRight: '8px',
    marginBottom: '5px',
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  boardsContainer: {
    '& > *': {},
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  biographyText: {
    whiteSpace: 'pre-wrap',
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: 400,
  },
  boardMeetingUpperBar: {
    width: '100%',
    backgroundColor: '#7F00FF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    height: '60px',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    padding: isSM ? '15px' : '10px',
  },
  p2pUpperBar: {
    width: '100%',
    backgroundColor: '#24A0A3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    height: '50px',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    padding: isSM ? '15px' : '20px',
  },
  benefitsText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#646D7A',
  },
  benefitsContainer: {
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
    justifyContent: 'left',
    marginTop: '20px',
    marginBottom: '10px',
  },
  benefitItem: {
    flex: 1,
    overflow: 'hidden',
    border: '1px solid #EFF0F7',
    borderRadius: '12px',
    marginRight: isSM ? '0px' : '1rem',
    marginBottom: isSM ? '20px' : '0px',
    maxWidth: '500px',
    padding: '24px',
  },
  benefitsDetailsText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#646D7A',
  },
  benefitsCategoryText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#232B35',
    whiteSpace: 'nowrap',
    marginBottom: '11px',
  },
});

const categoryOptions = [
  {
    text: 'Compensation Potential',
    icon: 'benefits-compensation',
  },
  {
    text: 'Equity Potential',
    icon: 'benefits-equity',
  },
  {
    text: 'Formal Roles',
    icon: 'benefits-roles',
  },
  {
    text: 'Perks',
    icon: 'benefit-perk',
  },
];

const CompanyOpportunitySlide = (props) => {
  let {
    company,
    skills,
    companyMembers,
    handleClickAway,
    hidePostSection,
    isFreemiumCompany,
    isAcPlusCompany,
    invitation,
  } = props;
  const { type, isFreemium, accountDetails } = useAccount();
  const p2pFlag = company.boardType == 'P2P';
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down([1230]));
  const disabledCompany =
    company.opportunityStage === 'dormant' ||
    company.opportunityStage === 'pending_cancellation' ||
    company.boardOwnerStatus === 'inactive' ||
    company.boardOwnerStatus === 'paused';
  const classes = useStyles(isSM, disabledCompany);
  const [companyCEO, setCompanyCEO] = useState('');
  const [showUpperBar] = useState(
    (company.upcomingBoardMeeting &&
      !isDateExpired(company.upcomingBoardMeeting)) ||
      company.boardType == 'P2P'
  );

  let featuredBenefits = [];
  if (company.benefits && company.benefits.length) {
    featuredBenefits = company.benefits.filter((b) => b.featured).slice(0, 3);
  }

  useEffect(() => {
    if (companyMembers.length > 0) {
      setCompanyCEO(companyMembers[0]);
    }
  }, [companyMembers]);

  function renderCompanyBiographyText() {
    if (!company) return;
    if (!company.storyAndMission) return;
    return (
      <>
        <Typography variant="body1" sx={classes.biographyText}>
          {company.storyAndMission}
        </Typography>
      </>
    );
  }

  const desiredSkills = company.desiredSkillsTagIds
    ? company.desiredSkillsTagIds
    : [];

  skills = skills
    ? skills.filter((skill) => desiredSkills.includes(skill.id))
    : [];

  function conditionallyRenderUpperBar() {
    if (isAcPlusCompany && type == AccountType.ADVISOR) {
      const isInvited =
        accountDetails.boards &&
        accountDetails.boards.invitedBoards &&
        accountDetails.boards.invitedBoards.some((bc) => bc.id === company.id);
      if (isInvited) {
        return (
          <div style={classes.boardMeetingUpperBar}>
            <Typography variant="body1" sx={classes.boardMeetingUpperBarText}>
              Accepting this invitation does not affect your total available
              board seats on your current membership
            </Typography>
          </div>
        );
      }
    }
    if (company.upcomingBoardMeeting) {
      return !isDateExpired(company.upcomingBoardMeeting) ? (
        <div style={classes.boardMeetingUpperBar}>
          <Typography variant="body1" sx={classes.boardMeetingUpperBarText}>
            {getUpcomingBoardMeetingValue(company.upcomingBoardMeeting, true)}
          </Typography>
        </div>
      ) : null;
    }
    if (p2pFlag) {
      return (
        <div style={classes.p2pUpperBar}>
          <Typography variant="body1" sx={classes.boardMeetingUpperBarText}>
            Joining a Peer Advisor Board will not count towards the number of
            board seats provided by your membership
          </Typography>
        </div>
      );
    }
  }

  function getUpcomingBoardMeetingValue(
    upcomingBoardMeeting,
    returnString = false
  ) {
    if (!isNil(upcomingBoardMeeting)) {
      if (!isDateExpired(upcomingBoardMeeting)) {
        if (returnString) {
          const meetingDate = new Date(upcomingBoardMeeting);
          const meetingText = `Join us for our next Board Meeting coming up on ${formatBoardJoinedDate(
            meetingDate
          )}. New advisors welcome!`;
          return meetingText;
        }
        return true;
      }
    }
    return false;
  }

  function getIconByText(text) {
    const option = categoryOptions.find((option) => option.text === text);
    return option ? option.icon : '';
  }

  function formatDetails(text) {
    var urlPattern = /(https?:\/\/[^\s]+)/g;
    var cleanedText = text.replace(urlPattern, '');

    cleanedText = cleanedText.substring(0, 250);

    return cleanedText;
  }

  function getBioTitle() {
    if (isAcPlusCompany && (!company || !company.storyAndMission)) {
      return '';
    }
    if (p2pFlag) {
      return t('ABOUT-PEER-LABEL');
    }
    return t('ABOUT-US-LABEL');
  }

  return (
    <>
      {conditionallyRenderUpperBar()}
      {disabledCompany && (
        <Box
          sx={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            zIndex: '10',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              backgroundColor: '#F9F9F9',
              opacity: '0.7',
              zIndex: '-1',
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: 'black',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '14px',
              position: 'relative',
              borderRadius: '30px',
            }}
          >
            <Typography color="white" sx={{ fontSize: '16px' }}>
              This company is not currently accepting new advisors.
            </Typography>
          </Box>
        </Box>
      )}
      <Paper sx={classes.card}>
        <div style={classes.companyInformationSectionContainer}>
          <div style={classes.mainInformationContainer}>
            <CompanySlideHeader
              invitation={invitation}
              handleClickAway={handleClickAway}
              company={company}
              showUpperBar={showUpperBar}
              companyCEO={companyCEO}
              p2pFlag={p2pFlag}
              isFreemiumCompany={isFreemiumCompany}
              isAcPlusCompany={isAcPlusCompany}
            />
          </div>

          <Typography variant="body1" sx={{ ...classes.tagsTitle }}>
            {getBioTitle()}
          </Typography>
          {renderCompanyBiographyText()}
          {!p2pFlag ? (
            <>
              <Typography variant="body1" sx={{ ...classes.tagsTitle }}>
                {t('LEADERSHIP-LABEL')}
              </Typography>
              <div style={classes.boardsContainer}>
                {companyMembers ? (
                  <>
                    {companyMembers.map((member) => (
                      <Box className={classes.generalMargin} key={member.id}>
                        <CompanyContact
                          contact={member}
                          customAvatar={() => (
                            <Avatar
                              sx={{ width: '40px', height: '40px' }}
                              src={
                                member.contactLogoId
                                  ? member.contactLogoLocation
                                  : DefaultAdvisorAvatar
                              }
                            />
                          )}
                        />
                      </Box>
                    ))}
                  </>
                ) : null}
              </div>
            </>
          ) : null}
          {!p2pFlag && !isFreemiumCompany && !isFreemium && (
            <>
              {isAcPlusCompany && (!skills || skills.length == 0) ? null : (
                <Typography variant="body1" sx={classes.tagsTitle}>
                  {t('SEEKING-GUIDANCE-ON-LABEL')}
                </Typography>
              )}
              <div className="skillTagBlock" style={classes.tagsContainer}>
                {skills.map((skill, index) => (
                  <Box sx={classes.generalMargin} key={index}>
                    <Tag label={skill.label} />
                  </Box>
                ))}
              </div>
            </>
          )}
          {featuredBenefits && featuredBenefits.length ? (
            <div id="benefitsSection">
              <Typography variant="body1" sx={classes.tagsTitle}>
                {t('BENEFITS-LABEL')}
              </Typography>
              <Typography variant="body1" sx={classes.benefitsText}>
                {t('BENEFITS-DESCRIPTION', {
                  companyName: company.displayName,
                })}
              </Typography>
              <div style={classes.benefitsContainer}>
                {featuredBenefits &&
                  featuredBenefits.map((benefit) => (
                    <div
                      className="featureBenefitItem"
                      style={classes.benefitItem}
                    >
                      <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '8px', marginTop: '-5px' }}>
                          <CustomIcon
                            iconname={getIconByText(benefit.category)}
                            color="primary"
                            fontSize="small"
                            style={{
                              color: '#232B35',
                              display: 'inline',
                            }}
                          />
                        </div>
                        <Typography sx={classes.benefitsCategoryText}>
                          {benefit.category}
                        </Typography>
                      </div>
                      <Typography sx={classes.benefitsDetailsText}>
                        {formatDetails(benefit.details)}
                      </Typography>
                    </div>
                  ))}
              </div>
            </div>
          ) : null}
        </div>
        {!hidePostSection &&
          type === AccountType.ADVISOR &&
          !isFreemiumCompany &&
          !isAcPlusCompany && (
            <CompanyBoardPreview
              invitation={invitation}
              company={company}
              companyId={company.id}
              companyName={company.displayName}
              companyImage={`https://${company.image.location}`}
              companyCEO={companyCEO}
            />
          )}
        {!hidePostSection &&
          type === AccountType.ADVISOR &&
          isAcPlusCompany && (
            <AcPlusPreview
              company={company}
              companyId={company.id}
              companyName={company.displayName}
              companyImage={`https://${company.image.location}`}
              companyCEO={companyCEO}
            />
          )}
      </Paper>
    </>
  );
};

export default CompanyOpportunitySlide;
