import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  useMediaQuery,
} from '@mui/material';
import { ArrowForwardIosOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useLocation, useHistory } from 'react-router-dom';
import ACSnackbar from './../../ACSnackbar';
import InviteToJoinConfirmationModal from './../../Company/InviteToJoinConfirmationModal';
import { getConversationMembers } from './../../../services/conversation_services';
import { inviteToJoin } from './../../../services/company_services';
import { SnackbarType } from './../../../services/utils/types';
import { useAccount } from './../../../contexts/Account';
import ActionButton from './../../Advisor/Profile/components/actionsButton';
import eventBus, { eventBusValues } from './../../../eventBus';
import { sendFSEventInvitedAdvisor } from '../../../services/FullStory';

const useStyles = (isSM) => ({
  root: {
    backgroundColor: '#F9FBFF',
    color: 'black',
    boxShadow: 'none',
    borderBottom: '1px solid #EFEFEF',
    position: 'fixed',
    zIndex: '1',
    width: isSM ? '100%' : '80%',
    minWidth: isSM ? '100%' : 'calc(100% - 350px)',
    maxWidth: isSM ? '100%' : 'calc(100% - 280px)',
  },
  rootDrawer: {
    backgroundColor: '#F9FBFF',
    color: 'black',
    boxShadow: 'none',
    borderBottom: '1px solid #EFEFEF',
    position: 'fixed',
    width: isSM ? '90vw' : '60vw',
    zIndex: '1',
  },
  name: {
    fontSize: '16px',
    fontWeight: 700,
    marginLeft: '10px',
    fontFamily: 'Poppins',
  },
  position: {
    fontSize: '10px',
    fontWeight: 400,
    marginLeft: '10px',
    fontFamily: 'Poppins',
    color: '#232B35',
  },
  button: {
    width: '150px',
    borderRadius: '40px',
    backgroundColor: '#6736FA',
    textTransform: 'none',
    height: '40px',
    margin: '5px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#FFFFFF',
    padding: '5px',
    '&:hover': {
      backgroundColor: '#6736FA',
    },
  },
  iframeContainer: {
    position: 'relative',
    paddingBottom: '50%',
    height: '750px',
    width: '755px',
    overflow: 'hidden',
  },
  iframe: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    overflowX: 'visible',
  },
});

export default function Header({ propConversationId, isDrawer, groupDates }) {
  const [conversationId, setConversationId] = useState('');
  const [sender, setSender] = useState({});
  const [senderName, setSenderName] = useState('');
  const [senderPosition, setSenderPosition] = useState('');
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const [showInviteToJoinConfirmation, setShowInviteToJoinConfirmation] =
    useState(false);
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const {
    accountDetails,
    asLoggedUserDetails,
    type,
    conversations,
    isFreeTrial,
    isFreemium,
  } = useAccount();
  const [snackbarSuccess, setSnackbarSuccess] = useState(false);
  const [snackbarWarning, setSnackbarWarning] = useState(false);
  const [inviteEnabled, setInviteEnabled] = useState(true);
  const [openMeetIFrame, setOpenMeetIFrame] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [meetUrl, setMeetUrl] = useState(false);
  const [isOnBoard, setIsOnBoard] = useState(false);
  const [invitationNote, setInvitationNote] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function getMembers(conversationParam, senderParam) {
    if (conversationParam) {
      setConversationId(conversationParam);
    }
    const params = conversationParam
      ? {
        senderId: asLoggedUserDetails.userId,
        conversationId: conversationParam,
      }
      : {
        senderId: asLoggedUserDetails.userId,
        receiver: senderParam,
      };
    getConversationMembers(params)
      .then(({ data }) => {
        if (data.getConversationMembers) {
          const senderResponse = data.getConversationMembers;
          setSenderName(senderResponse.displayName);
          setSender(senderResponse);
          if (accountDetails.boardAdvisors) {
            const boardMember = accountDetails.boardAdvisors.some(
              (x) => x.id === senderResponse.id
            );
            const boardInvitation = accountDetails.invitedAdvisors.some(
              (x) => x.id === senderResponse.id
            );
            setInviteEnabled(!(boardMember || boardInvitation));
          }
          setSenderPosition(senderResponse.title || 'Advisor');
        }
      })
      .catch(() => {
        history.push('/messages');
      });
  }

  useEffect(() => {
    if (propConversationId) {
      getMembers(propConversationId);
    }
  }, [propConversationId]);

  function hideMenu() {
    if (propConversationId) {
      eventBus.dispatch(eventBusValues.triggerConversationDrawer, {
        close: true,
      });
    } else {
      eventBus.dispatch(eventBusValues.onHideMenu, {
        show: true,
      });
    }
  }

  function sendFSEventOnInvite({ outcome, inviteId }) {
    sendFSEventInvitedAdvisor({
      advisorId: sender.id,
      outcome,
      inviteId: inviteId || '',
      source: 'conversation',
      invitationNote: invitationNote,
      invitationIncludedNote: invitationNote !== "" ? true : false
    });
  }

  function inviteToJoinCall(advisorId) {
    setIsLoading(true)
    inviteToJoin({
      ADVISOR_ID: advisorId,
      COMPANY_ID: accountDetails.id,
      USER_ID: asLoggedUserDetails.userId,
      INVITATION_NOTE: invitationNote
    })
      .then(({ data }) => {
        setSnackMessage(`${sender.displayName} has been invited to your board`);
        setSnackbarSuccess(true);
        sendFSEventOnInvite({
          outcome: 'invited',
          inviteId: data.inviteAdvisorToJoin.id,
        });
        if (accountDetails.invitedAdvisors) {
          accountDetails.invitedAdvisors.push({
            id: sender.id,
            inviteDate: new Date().toISOString(),
          });
        } else {
          accountDetails.invitedAdvisors = [
            {
              id: sender.id,
              inviteDate: new Date().toISOString(),
            },
          ];
        }
        setIsLoading(false)
        setShowInviteToJoinConfirmation(false);
      })
      .catch((e) => {
        setIsLoading(false)
        sendFSEventOnInvite({
          outcome: 'error',
        });
        setShowInviteToJoinConfirmation(false);
        console.log(e);
        if (e.errors && e.errors[0]) {
          const err = JSON.parse(e.errors[0].message);
          const { code } = err;
          if (code && code === 'UnsuccessfullyInvitedToJoin') {
            setSnackMessage(err.error);
            setSnackbarWarning(true);
          }
        }
      });
  }

  function confirmInviteToJoin() {
    inviteToJoinCall(sender.id);
    setShowInviteToJoinConfirmation(false);
  }

  function senderExistsInBoardAdvisors() {
    if (!accountDetails || !accountDetails.boardAdvisors) {
      return false;
    }
    return accountDetails.boardAdvisors.some(
      (advisor) => advisor.id === sender.id
    );
  }

  useEffect(() => {
    const result = senderExistsInBoardAdvisors();
    setIsOnBoard(result);
  }, [accountDetails, sender]);

  useEffect(() => {
    if (
      queryParams.get('senderId') &&
      queryParams.get('senderId') === asLoggedUserDetails.userId
    ) {
      history.push('/messages');
    } else if (
      queryParams.get('conversationId') ||
      queryParams.get('senderId')
    ) {
      getMembers(
        queryParams.get('conversationId'),
        queryParams.get('senderId')
      );
    }
    const hsubs = history.listen((locationParams) => {
      const newQueryParams = new URLSearchParams(locationParams.search);
      const conversationParam = newQueryParams.get('conversationId');
      const senderParam = newQueryParams.get('senderId');
      if (!conversationParam && !senderParam && !propConversationId) {
        setConversationId('');
        setSenderName('');
        setSenderPosition('');
        setSender({});
      }
      if (
        (conversationParam && conversationParam !== conversationId) ||
        senderParam
      ) {
        setSenderName('');
        setSenderPosition('');
        getMembers(conversationParam, senderParam);
      }
    });
    return () => {
      hsubs();
    };
  }, []);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{
            ...(isDrawer ? classes.rootDrawer : classes.root),
            ...(type === 'company' ? { backgroundColor: '#FBFCFD' } : {}),
          }}
        >
          <Toolbar style={{ paddingLeft: '20px' }}>
            <div
              style={{
                flexGrow: 2,
                mr: 2,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {(isSM && conversations[0]) || isDrawer ? (
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  onClick={() => hideMenu(true)}
                  component="label"
                >
                  <ArrowForwardIosOutlined />
                </IconButton>
              ) : null}
              <Typography
                onClick={() => {
                  if (sender.accountType === 'advisor') {
                    eventBus.dispatch(
                      eventBusValues.triggerAdvisorDrawerAction,
                      {
                        fetchAdvisor: true,
                        id: sender.id,
                        boardRoomView: true,
                      }
                    );
                  } else {
                    let companyIdToSend = null;
                    for (let item of groupDates) {
                      for (let message of item.messages) {
                        if (message.senderId === sender.id) {
                          companyIdToSend = message.profile.companyId;
                          break;
                        }
                      }

                      if (companyIdToSend) {
                        break;
                      }
                    }
                    if (companyIdToSend) {
                      eventBus.dispatch(
                        eventBusValues.triggerCompanyDrawerAction,
                        {
                          companyId: companyIdToSend,
                        }
                      );
                    }
                  }
                }}
                variant="h6"
                component="div"
                sx={{ ...classes.name, cursor: 'pointer' }}
              >
                {senderName}
              </Typography>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  ...classes.position,
                  display: { xxs: 'none', sm: 'block' },
                }}
              >
                {senderPosition}
              </Typography>
            </div>
            <Box sx={{ flexGrow: 1 }} />
            {sender.accountType === 'advisor' ? (
              <ActionButton
                advisor={sender}
                splitButton
                isOnBoard={isOnBoard}
                onInvite={() => {
                  if (isFreeTrial) {
                    eventBus.dispatch(
                      eventBusValues.triggerCompanyUpgradeAction
                    );
                  } else {
                    setShowInviteToJoinConfirmation(true);
                  }
                }}
              />
            ) : null}
          </Toolbar>
        </AppBar>
      </Box>
      <InviteToJoinConfirmationModal
        isLoading={isLoading}
        isVisible={showInviteToJoinConfirmation}
        advisor={sender}
        onConfirm={() => confirmInviteToJoin()}
        onClose={() => {
          setShowInviteToJoinConfirmation(false);
          sendFSEventOnInvite({ outcome: 'cancelled' });
        }}
        setInvitationNote={setInvitationNote}
      />
      <ACSnackbar
        open={snackbarSuccess}
        text={snackMessage}
        severity={SnackbarType.SUCCESS}
        onClose={() => setSnackbarSuccess(false)}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '100px' : '60px' }}
        sx={{marginTop: isFreemium ? '100px' : '60px' }}
      />
      <ACSnackbar
        open={snackbarWarning}
        text={snackMessage}
        severity={SnackbarType.WARNING}
        onClose={() => setSnackbarWarning(false)}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <Dialog
        open={openMeetIFrame}
        fullScreen={isSM}
        maxWidth="md"
        height="md"
        onClose={() => setOpenMeetIFrame(false)}
      >
        <div style={classes.iframeContainer}>
          <iframe
            src={meetUrl}
            title="Meet"
            frameBorder="0"
            style={classes.iframe}
          />
        </div>
      </Dialog>
    </>
  );
}
