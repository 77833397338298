import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  useMediaQuery,
  Checkbox,
  Button,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  getTopAdvisors,
  inviteToJoin,
} from '../../services/company_services/index';
import { useAccount } from '../../contexts/Account';
import AdvisorsTopBar from '../../components/Advisor/TopBar';
import CompanyEmptyState from '../../components/EmptyState/CompanyEmptyState';
import ACSnackbar from '../../components/ACSnackbar';
import eventBus, { eventBusValues } from '../../eventBus';
import TopMatchesList from '../../components/Advisor/TopMatchesList';
import GenericConfirmationModal from '../../components/GenericConfirmationModal';

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: '#4BC6FF',
  '&.Mui-checked': {
    color: '#4BC6FF',
  },
  '&.MuiCheckbox-indeterminate': {
    color: '#4BC6FF',
  },
}));

const getClasses = (isSM) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    paddingBottom: '20px',
  },
  containerTopBar: {
    paddingTop: '15px',
    borderBottom: '1px solid #EFF0F6',
    paddingRight: isSM ? '10px' : '30px',
    paddingLeft: isSM ? '10px' : '30px',
  },
  containerTopBarArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '1200px',
  },
  checkboxSection: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
  },
  buttonSection: {
    display: 'flex',
    alignItems: 'center',
  },
  selectedCount: {
    marginLeft: '10px',
    color: '#232B35',
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '-0.43px',
  },
  inviteButton: {
    marginLeft: '20px',
    backgroundColor: '#4BC6FF',
    color: '#FFFFFF',
    borderRadius: '25px',
    textTransform: 'none',
    width: '220px',
    height: '48px',
    fontWeight: 700,
    marginBottom: '15px',
    '&:hover': {
      backgroundColor: '#4BC6FF',
    },
    '&:disabled': {
      backgroundColor: '#E0E0E0',
      opacity: '1',
    },
  },
  loadMoreButton: {
    marginLeft: '20px',
    color: '#232B35',
    textTransform: 'none',
    size: '16px',
    fontWeight: 500,
    letterSpacing: '-0.43px',
    height: '48px',
    marginBottom: '15px',
  },
});

const PAGE_SIZE = 10;

function TopMatchesAdvisors(props) {
  const { boxRef } = props;
  const {
    accountDetails,
    currentUserInfo,
    asLoggedUserDetails,
    isImpersonated,
    isFreemium,
    isFreeTrial,
  } = useAccount();
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [myAdvisors, setMyAdvisors] = useState([]);
  const [selectedAdvisors, setSelectedAdvisors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const navRef = useRef();
  const [sticky, setSticky] = useState(false);
  const contact = isImpersonated
    ? accountDetails.contacts.find((c) => c.id === asLoggedUserDetails.userId)
    : accountDetails.contacts.find((c) => c.id === currentUserInfo.username);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = getClasses(isSM);
  const boardAdvisorsIds =
    accountDetails.boardAdvisors.map((ba) => ba.id) || [];
  const [endPagination, setEndPagination] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  useEffect(() => {
    loadAdvisors();
  }, []);

  function onScroll() {
    if (navRef && navRef.current && navRef.current.getBoundingClientRect) {
      setSticky(
        navRef.current.getBoundingClientRect().top < (isFreeTrial ? 155 : 105)
      );
    }
  }

  useEffect(() => {
    if (boxRef && boxRef.current) {
      boxRef.current.addEventListener('scroll', onScroll);
    }

    return () => {
      if (boxRef && boxRef.current) {
        boxRef.current.removeEventListener('scroll', onScroll);
      }
    };
  }, []);

  function openAdvisorProfile(advisor) {
    eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, advisor);
  }

  function inviteToJoinMultiple() {
    const invitePromises = selectedAdvisors.map((advisorId) =>
      inviteToJoin({
        ADVISOR_ID: advisorId,
        COMPANY_ID: accountDetails.id,
        USER_ID: asLoggedUserDetails.userId,
        IS_TOP_MATCH: true,
        INVITATION_NOTE: '',
      })
    );

    Promise.all(invitePromises)
      .then((resp) => {
        if (selectedAdvisors.length === 1) {
          setSuccessMessage(
            'Selected advisor has been invited to join your advisor board'
          );
        } else {
          setSuccessMessage(
            `${selectedAdvisors.length} advisors has been invited to join your advisor board`
          );
        }
        setSuccessSnackbar(true);
        setMyAdvisors((prevAdvisors) =>
          prevAdvisors.filter(
            (advisor) => !selectedAdvisors.includes(advisor.id)
          )
        );
        setSelectedAdvisors([]);
      })
      .catch((e) => {
        if (e.errors && e.errors.length > 0 && e.errors[0].message) {
          const errorMessage = JSON.parse(e.errors[0].message).error;
          setSuccessMessage(`Error inviting advisor: ${errorMessage}`);
          setSuccessSnackbar(true);
        }
      })
      .finally(() => {
        setConfirmationModal(false);
      });
  }

  const loadAdvisors = () => {
    setIsLoading(true);
    getTopAdvisors({
      COMPANY_ID: accountDetails.id,
      USER_ID: contact.id,
      LIMIT: page === 0 ? 50 : PAGE_SIZE,
      EXCEPTIONS: boardAdvisorsIds.concat(
        myAdvisors.map((advisor) => advisor.id)
      ),
    })
      .then(({ data }) => {
        setMyAdvisors((prevAdvisors) => [
          ...prevAdvisors,
          ...data.getTopAdvisors.advisors,
        ]);
        setSelectedAdvisors((prevAdvisors) => [
          ...prevAdvisors,
          ...data.getTopAdvisors.advisors.map((advisor) => advisor.id),
        ]);
        setPage((prevPage) => prevPage + 1);
        if (
          data.getTopAdvisors.advisors.length < (page === 0 ? 50 : PAGE_SIZE)
        ) {
          setEndPagination(true);
        }
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedAdvisors(myAdvisors.map((advisor) => advisor.id));
    } else {
      setSelectedAdvisors([]);
    }
  };

  const handleAdvisorSelect = (id) => {
    setSelectedAdvisors((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((advisorId) => advisorId !== id)
        : [...prevSelected, id]
    );
  };

  return (
    <>
      <AdvisorsTopBar boxRef={boxRef} isTopMatches />
      <div style={classes.container}>
        <Box sx={classes.containerTopBar}>
          <Box sx={classes.containerTopBarArea} id="topMatchesSubNav">
            <Box sx={classes.checkboxSection}>
              <CustomCheckbox
                id="topMatchesSelectedCheckbox"
                checked={selectedAdvisors.length === myAdvisors.length}
                onChange={handleSelectAll}
                indeterminate={
                  selectedAdvisors.length > 0 &&
                  selectedAdvisors.length < myAdvisors.length
                }
              />
              <Typography variant="body1" sx={classes.selectedCount}>
                {selectedAdvisors.length} Selected
              </Typography>
            </Box>
            <Box sx={classes.buttonSection}>
              <Button
                sx={classes.loadMoreButton}
                onClick={loadAdvisors}
                // disabled={endPagination}
                startIcon={<RefreshIcon sx={{ color: '#4BC6FF' }} />}
              >
                <span id="topMatchesLoadMoreButton">Load 10 more</span>
              </Button>
              {!isSM ? (
                <Button
                  sx={classes.inviteButton}
                  onClick={() => setConfirmationModal(true)}
                  disabled={selectedAdvisors.length === 0}
                >
                  <span id="topMatchesInviteButton">
                    Invite {selectedAdvisors.length} Top Matches
                  </span>
                </Button>
              ) : null}
            </Box>
          </Box>
          {isSM ? (
            <>
              <div
                style={{ display: 'flex', width: '1px', height: '0px' }}
                ref={navRef}
              />
              <Box
                sx={{
                  ...classes.buttonSection,
                  position: sticky ? 'fixed' : null,
                  top: `${isFreeTrial ? '155px' : '105px'}`,
                  width: '100%',
                  backgroundColor: 'white',
                  zIndex: 1000,
                  padding: sticky ? '0 10px' : '0px',
                  marginLeft: sticky ? '-10px' : '0px',
                }}
              >
                <Button
                  sx={{ ...classes.inviteButton, width: '100%', marginLeft: 0 }}
                  onClick={() => setConfirmationModal(true)}
                  disabled={selectedAdvisors.length === 0}
                  id="topMatchesInviteButton"
                >
                  Invite {selectedAdvisors.length} Top Matches
                </Button>
              </Box>
            </>
          ) : null}
        </Box>
        <Box pt="30px" sx={{ maxWidth: '1260px' }}>
          <TopMatchesList
            onClickAction={openAdvisorProfile}
            advisorList={myAdvisors}
            incrementOffset={50}
            endPagination={endPagination}
            removeBelowButtons
            fetchAdvisor={false}
            onSelectAdvisor={handleAdvisorSelect}
            selectedAdvisors={selectedAdvisors}
            CustomCheckbox={CustomCheckbox}
            loadAdvisors={loadAdvisors}
            isLoading={isLoading}
          />
        </Box>
        {!isLoading && myAdvisors.length === 0 && (
          <Box
            sx={{
              padding: '40px',
            }}
          >
            <CompanyEmptyState />
          </Box>
        )}
      </div>
      {confirmationModal ? (
        <GenericConfirmationModal
          isVisible={confirmationModal}
          confirmationMessage={`Are you sure you want to invite ${selectedAdvisors.length} selected advisors to your advisor board?`}
          onConfirm={inviteToJoinMultiple}
          onClose={() => setConfirmationModal(false)}
        />
      ) : null}
      <ACSnackbar
        open={successSnackbar}
        text={successMessage}
        severity="success"
        onClose={() => setSuccessSnackbar(false)}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
    </>
  );
}

export default TopMatchesAdvisors;
