import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  Typography,
  TextField,
  InputAdornment,
  Box,
  useMediaQuery,
  Chip,
} from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { styled, useTheme } from '@mui/material/styles';
import { useAccount } from '../../contexts/Account';
import CustomAutocomplete from '../CustomAutocompleteMobile';
import { sendFSAdvisorsSearched } from '../../services/FullStory';

const typeOptions = [
  { label: 'Advisory Boards', value: 'Company' },
  { label: 'Peer Advisory Boards', value: 'P2P' },
];

const CustomOutlinedInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    height: '30px',
    padding: '7px 5px 7px 0px',
    '&::placeholder': {
      padding: '0 -5px',
    },
  },
  '& .MuiOutlinedInput-root': {
    alignItems: 'center',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '15px',
    border: 'solid 1px #E6EDFF',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'inherit',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
  '& .MuiInputAdornment-root': {
    height: '100%',
    alignItems: 'center',
  },
}));

const getStyles = (isSM) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    maxWidth: '100%',
    backgroundColor: 'white',
    borderRadius: '5px',
    marginTop: '-1px',
  },
  keywordsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: isSM ? '0' : '19px',
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
    padding: '0 0 4px',
  },
  autocomplete: {
    marginTop: isSM ? '30px' : '0px',
    width: isSM ? '100%' : '34%',
    paddingRight: isSM ? '0px' : '16px',
  },
});

function CompaniesFilter(props) {
  const { tags, accountDetails } = useAccount();
  const {
    onSearchButtonClicked,
    hidePeerFilter,
    scrollToInput,
    isAdvisorSearch,
  } = props;
  const theme = useTheme();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down(900));
  const styles = getStyles(isSM);
  const [keywords, setKeywords] = useState('');
  const [skills, setSkills] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [type, setType] = useState([]);
  const [filtersControl, setFiltersControl] = useState([false, false, false]);
  const [isTagSelectedIndustry, setIsTagSelectedIndustry] = useState(false);
  const [isTagSelectedSkills, setIsTagSelectedSkills] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const location = useLocation();

  const { t } = useTranslation();

  const timeoutRef = useRef();
  const fsTimeoutRef = useRef();

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (fsTimeoutRef.current) {
      clearTimeout(fsTimeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      onSearchButtonClicked({
        keywords,
        skills,
        industries,
        position: '',
      });
    }, 1000);

    if (isAdvisorSearch) {
      timeoutRef.current = setTimeout(() => {
        sendFSAdvisorsSearched({
          accountDetails,
          keywords: keywords,
          skills: skills.map((skill) => skill.label).join(','),
          industries: industries.map((industry) => industry.label).join(','),
          invitations: '',
        });
      }, 10000);
    }
  }, [keywords, skills, industries]);

  function closeAutocomplete() {
    setFiltersControl([false, false, false]);
    onSearchButtonClicked({
      keywords,
      skills,
      position: '',
      industries,
      type,
    });
  }

  const handleRemoveAllSkills = () => {
    setSkills([]);
    setIsTagSelectedSkills(false);
  };

  const handleRemoveAllIndustries = () => {
    setIndustries([]);
    setIsTagSelectedIndustry(false);
  };

  const handleRemoveSkill = (skillToRemove) => {
    const newSkills = skills.filter((skill) => skill !== skillToRemove);
    setSkills(newSkills);
    setIsTagSelectedSkills(newSkills.length > 0);
  };

  const handleRemoveIndustry = (industryToRemove) => {
    const newIndustries = industries.filter(
      (industry) => industry !== industryToRemove
    );
    setIndustries(newIndustries);
    setIsTagSelectedIndustry(newIndustries.length > 0);
  };

  function peerFilterComponent() {
    if (hidePeerFilter) {
      return <Box sx={{ ...styles.autocomplete, paddingRight: '0px' }} />;
    }
    return (
      <Autocomplete
        sx={{ ...styles.autocomplete, paddingRight: '0px' }}
        id="search-advisor-types-autocomplete"
        data-testid="search-advisor-types-autocomplete"
        data-cy="search-advisor-types-autocomplete"
        open={filtersControl[2]}
        onOpen={() => setFiltersControl([false, false, true])}
        onChange={(e, value) => setType(value)}
        onClose={() => closeAutocomplete()}
        getOptionLabel={(options) => options.label}
        options={typeOptions}
        renderTags={(value) => (
          <Typography
            style={{
              whiteSpace: 'normal',
              height: 18,
              fontSize: 14,
              fontWeight: 400,
              fontFamily: 'Poppins',
              marginLeft: 5,
            }}
          >
            {`${value.length} ${value.length > 1 ? 'types' : 'type'} selected`}
          </Typography>
        )}
        multiple
        limitTags={2}
        disableCloseOnSelect
        renderInput={(params) => (
          <CustomOutlinedInput
            {...params}
            InputProps={{
              ...params.InputProps,
              style: {
                borderRadius: isSM ? '15px' : '10px',
                paddingLeft: isSM ? '10px' : '5px',
                height: '44px',
              },
            }}
            size="small"
            placeholder={type.length > 0 ? '' : t('PUBLIC-ADVISOR-TYPE-LABEL')}
            variant="outlined"
          />
        )}
      />
    );
  }

  return (
    <form style={styles.container}>
      <div style={styles.keywordsContainer} className="searchBox">
        <CustomOutlinedInput
          sx={{
            display: 'flex',
            flex: 'auto',
            borderRadius: isSM ? '15px' : '10px',
            border: '1px solid rgba(0, 0, 0, 0)',
          }}
          id="search-advisor-keyword-input"
          data-testid="search-advisor-keyword-input"
          data-cy="search-advisor-keyword-input"
          placeholder={
            location.pathname === '/networking'
              ? 'Search by keyword, eg. marketing or "digital marketing"'
              : 'Search by Keywords'
          }
          value={keywords}
          onChange={(evt) => setKeywords(evt.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ marginRight: '10px' }} position="start">
                <SearchOutlined
                  style={{ fontSize: '20px', color: '#c5ccd5' }}
                />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div style={styles.filtersContainer}>
        {isSM ? (
          <Box sx={{ marginBottom: '-20px' }}>
            <CustomAutocomplete
              items={tags.industries}
              selectedItemsProp={industries}
              placeholder="Industry"
              onItemsChange={setIndustries}
              onSearch={onSearchButtonClicked}
              scrollToInput={scrollToInput}
            />

            <CustomAutocomplete
              items={tags.skills}
              selectedItemsProp={skills}
              placeholder="Skills"
              onItemsChange={setSkills}
              onSearch={onSearchButtonClicked}
              scrollToInput={scrollToInput}
            />
          </Box>
        ) : (
          <>
            <Autocomplete
              sx={styles.autocomplete}
              id="search-advisor-skills-autocomplete"
              data-testid="search-advisor-skills-autocomplete"
              data-cy="search-advisor-skills-autocomplete"
              open={filtersControl[1]}
              onOpen={() => setFiltersControl([false, true, false])}
              onChange={(e, value) => {
                setSkills(value);
                setIsTagSelectedSkills(value.length > 0);
              }}
              onClose={() => closeAutocomplete()}
              options={tags.skills || []}
              getOptionLabel={(options) => options.label}
              multiple
              disableCloseOnSelect
              value={skills}
              renderInput={(params) => (
                <CustomOutlinedInput
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      borderRadius: '10px',
                      height: '44px',
                    },
                  }}
                  size="small"
                  placeholder={isTagSelectedSkills ? '' : 'Filter Skills'}
                  variant="outlined"
                />
              )}
              renderTags={() =>
                isTagSelectedSkills ? (
                  <Chip
                    label={`(${skills.length}) skills selected `}
                    onDelete={handleRemoveAllSkills}
                    sx={{
                      margin: '0',
                      padding: '0',
                      backgroundColor: '#F5F7FA',
                    }}
                  />
                ) : null
              }
              componentsProps={{
                clearIndicator: {
                  style: {
                    display: 'none',
                  },
                },
              }}
            />
            <Autocomplete
              sx={styles.autocomplete}
              id="search-advisor-industry-autocomplete"
              data-testid="search-advisor-industry-autocomplete"
              data-cy="search-advisor-industry-autocomplete"
              open={filtersControl[0]}
              onOpen={() => setFiltersControl([true, false, false])}
              onChange={(e, value) => {
                setIndustries(value);
                setIsTagSelectedIndustry(value.length > 0);
              }}
              onClose={() => closeAutocomplete()}
              getOptionLabel={(options) => options.label}
              options={tags.industries || []}
              multiple
              disableCloseOnSelect
              value={industries}
              renderInput={(params) => (
                <CustomOutlinedInput
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      borderRadius: '10px',
                      height: '44px',
                    },
                  }}
                  size="small"
                  placeholder={isTagSelectedIndustry ? '' : 'Filter Industry'}
                  variant="outlined"
                />
              )}
              renderTags={() =>
                isTagSelectedIndustry ? (
                  <Chip
                    label={`(${industries.length}) industries selected `}
                    onDelete={handleRemoveAllIndustries}
                    sx={{
                      margin: '0',
                      padding: '0',
                      backgroundColor: '#D7F1FD',
                    }}
                  />
                ) : null
              }
              componentsProps={{
                clearIndicator: {
                  style: {
                    display: 'none',
                  },
                },
              }}
            />
          </>
        )}

        {peerFilterComponent()}
      </div>
      <Box
        mt={2}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          maxHeight: !showMore ? '80px' : '100%',
          maxWidth: '1200px',
          overflow: 'hidden',
          transition: 'max-height 0.5s ease-in-out',
        }}
      >
        {industries.map((option, index) => (
          <Chip
            onDelete={() => handleRemoveIndustry(option)}
            key={index}
            label={option.label}
            sx={{ margin: '4px', backgroundColor: '#D7F1FD' }}
          />
        ))}
        {skills.map((option, index) => (
          <Chip
            onDelete={() => handleRemoveSkill(option)}
            key={index}
            label={option.label}
            sx={{ margin: '4px', backgroundColor: '#F5F7FA' }}
          />
        ))}
      </Box>
      {isMobile ? (
        <>
          {(skills.length > 3 ||
            industries.length > 3 ||
            skills.length + industries.length > 3) && (
            <Box
              sx={{
                display: 'flex',
                maxWidth: '1200px',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#E6EDFF',
                  height: '1px',
                  width: '100%',
                }}
              />
              <Typography
                onClick={() => setShowMore(!showMore)}
                sx={{
                  display: 'flex',
                  marginLeft: '20px',
                  marginRight: '20px',
                  color: '#74879A',
                  cursor: 'pointer',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                }}
              >
                {showMore ? 'Less' : 'More'}
                <ExpandCircleDownOutlinedIcon
                  sx={{
                    transform: showMore ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform .5s',
                    marginLeft: '5px',
                  }}
                />
              </Typography>
              <Box
                sx={{
                  backgroundColor: '#E6EDFF',
                  height: '1px',
                  width: '100%',
                }}
              />
            </Box>
          )}
        </>
      ) : (
        <>
          {(skills.length > 7 ||
            industries.length > 7 ||
            skills.length + industries.length > 13) && (
            <Box
              sx={{
                display: 'flex',
                maxWidth: '1200px',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#E6EDFF',
                  height: '1px',
                  width: '100%',
                }}
              />
              <Typography
                onClick={() => setShowMore(!showMore)}
                sx={{
                  display: 'flex',
                  marginLeft: '20px',
                  marginRight: '20px',
                  color: '#74879A',
                  cursor: 'pointer',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                }}
              >
                {showMore ? 'Less' : 'More'}
                <ExpandCircleDownOutlinedIcon
                  sx={{
                    transform: showMore ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform .5s',
                    marginLeft: '5px',
                  }}
                />
              </Typography>
              <Box
                sx={{
                  backgroundColor: '#E6EDFF',
                  height: '1px',
                  width: '100%',
                }}
              />
            </Box>
          )}
        </>
      )}
    </form>
  );
}

export default CompaniesFilter;
